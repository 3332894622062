import { createMuiTheme, Theme } from '@material-ui/core/styles';

import { default as DefaultThemeOptions } from './defaultThemeOptions';
import ColorPalettes, { PaletteProvider } from './colorPalettes';
import { ThemaName } from './types';

class ThemeService {
    createTheme = (themeName: ThemaName): Theme => {
        const tennantPalette = PaletteProvider.getPallete(themeName);
        const finalThemeOptions = { ...DefaultThemeOptions, palette: { ...tennantPalette, ...DefaultThemeOptions.palette } };

        return createMuiTheme(finalThemeOptions);
    };

    getThemeName = (tenantKey: string): ThemaName => {
        for (const themeKey in ColorPalettes) {
            if (themeKey === tenantKey) {
                return themeKey as ThemaName;
            }
        }

        return "default";
    };
}

export default new ThemeService();
