import { AxiosInstance } from 'axios';

import { BaseResponse } from '../responses';
import BaseJsonClient from './baseJsonClient';
import { IErrorHandler } from '../abstractions';

export default class BaseClient extends BaseJsonClient {
    protected readonly axiosInstance: AxiosInstance;
    protected readonly errorHandler: IErrorHandler;

    protected readonly modelPath: string;

    constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance, modelPath: string) {
        super(axiosInstance, modelPath);

        this.errorHandler = errorHandler;
        this.axiosInstance = axiosInstance;
        this.modelPath = modelPath;
    }

    protected async getHttpAsync<TResponse extends BaseResponse>(route: string, query: string): Promise<TResponse> {
        try {
            const response = await this.errorHandler.handleRequestAsync(async () => {
                return await super.getHttpAsync<TResponse>(route, query);
            });

            return response;
        } catch (error) {
            const errorResposne = new BaseResponse();
            errorResposne.resultCode = -1;
            errorResposne.resultReason = error.message;

            return errorResposne as TResponse;
        }
    }

    protected async putHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
        try {
            const response = await this.errorHandler.handleRequestAsync(async () => {
                return await super.putHttpAsync<TResponse, TData>(route, data);
            });

            return response;
        } catch (error) {
            const errorResposne = new BaseResponse();
            errorResposne.resultCode = -1;
            errorResposne.resultReason = error.message;

            return errorResposne as TResponse;
        }
    }

    protected async postHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
        try {
            const response = await this.errorHandler.handleRequestAsync(async () => {
                return await super.postHttpAsync<TResponse, TData>(route, data);
            });

            return response;
        } catch (error) {
            const errorResposne = new BaseResponse();
            errorResposne.resultCode = -1;
            errorResposne.resultReason = error.message;

            return errorResposne as TResponse;
        }
    }
}
