import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getDocumentsAsync, downloadDocumentAsync } from './actions';
import apiClientInstance from '../../api/apiClientInstance';
import { DocumentModel, DocumentType } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { selectDocumentById } from './selectors';
import Document from '../../models/document';
import { DownloadFileResponse } from '../downloadFile';
import { downloadFile } from '../downloadFile/actions';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([
        yield takeEvery(getDocumentsAsync.request, getDocumentsRequest),
        yield takeEvery(downloadDocumentAsync.request, downloadDocumentRequest)
    ]);
}

type GetDocumentsRequestGeneratorType =
    | Promise<BaseDataResponse<DocumentModel[]>>
    | PutEffect<ActionType<typeof getDocumentsAsync.success>>
    | PutEffect<ActionType<typeof getDocumentsAsync.failure>>;

function* getDocumentsRequest(
    action: ReturnType<typeof getDocumentsAsync.request>
): Generator<GetDocumentsRequestGeneratorType, void, BaseDataResponse<DocumentModel[]>> {
    // TODO: : Generator {
    try {
        console.warn('getDocumentsRequest');

        const response: BaseDataResponse<DocumentModel[]> = yield apiClientInstance.Documents.getAsync(DocumentType.GeneralInformation);
        if (response.resultCode === 0) {
            const orderedData = response.data.sort((a, b) => {
                if (a.publicFromUtc > b.publicFromUtc) {
                    return -1;
                }
                if (a.publicFromUtc < b.publicFromUtc) {
                    return 1;
                }

                return 0;
            });

            yield put(getDocumentsAsync.success(orderedData));
        } else {
            yield put(getDocumentsAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        yield put(getDocumentsAsync.failure(error));
    }
}

type DownloadDocumentRequestGeneratorType =
    | SelectEffect
    | Promise<Blob | null>
    | PutEffect<ActionType<typeof downloadDocumentAsync.success>>
    | PutEffect<ActionType<typeof downloadFile>>
    | PutEffect<ActionType<typeof downloadDocumentAsync.failure>>;

function* downloadDocumentRequest(
    action: ReturnType<typeof downloadDocumentAsync.request>
): Generator<DownloadDocumentRequestGeneratorType, void, Document & Blob> {
    // TODO: : Generator {
    try {
        console.warn('downloadDocumentRequest');

        const payroll: Document = yield select(selectDocumentById(action.payload));
        const blob: Blob = yield apiClientInstance.Documents.getPdfAsync(action.payload);

        if (blob !== undefined) {
            const downloadFileResponse: DownloadFileResponse = {
                data: blob,
                filename: payroll.fileName
            };
            yield put(downloadDocumentAsync.success());
            yield put(downloadFile(downloadFileResponse));
        } else {
            yield put(downloadDocumentAsync.failure(new Error('Stažení souboru se nezdařilo.')));
        }
    } catch (error) {
        yield put(downloadDocumentAsync.failure(error));
    }
}
