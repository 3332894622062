import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getPeriodsAsync, getPayrollsAsync, selectYear, downloadPayrollAsync, getPayrollPinCodeAsync } from './actions';
import apiClientInstance from '../../api/apiClientInstance';
import { PayrollModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { selectSelectedYear, selectPayrolTicketsByYear, selectPayrollById } from './selectors';
import { payrollModelsToPayrollTicketMapper } from './mappers';
import { PayrollTicket } from './types';
import { DownloadFileResponse } from '../downloadFile';
import { downloadFile } from '../downloadFile/actions';
import { addSuccessAlertWithTenantFilter } from '../alerts';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([
        yield takeEvery(getPeriodsAsync.request, getPeriodsRequest),
        yield takeEvery(getPeriodsAsync.success, getPeriodSuccess),
        yield takeEvery(getPayrollsAsync.request, getPayrollsRequest),
        yield takeEvery(selectYear, selectedYearChanged),
        yield takeEvery(downloadPayrollAsync.request, downloadPayrollRequest),
        yield takeEvery(getPayrollPinCodeAsync.request, getPayrollPinCodeRequest)
    ]);
}

type GetPeriodsRequestGeneratorType =
    | Promise<BaseDataResponse<number[]>>
    | PutEffect<ActionType<typeof getPeriodsAsync.success>>
    | PutEffect<ActionType<typeof getPeriodsAsync.failure>>;

function* getPeriodsRequest(
    action: ReturnType<typeof getPeriodsAsync.request>
): Generator<GetPeriodsRequestGeneratorType, void, BaseDataResponse<number[]>> {
    // TODO: : Generator {
    try {
        console.warn('getPeriodsRequest');
        const response: BaseDataResponse<number[]> = yield apiClientInstance.Payrolls.getPeriodsAsync();
        if (response.resultCode === 0) {
            yield put(getPeriodsAsync.success(response.data));
        } else {
            yield put(getPeriodsAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        yield put(getPeriodsAsync.failure(error));
    }
}

type GetPeriodSuccessGeneratorType =
    | SelectEffect
    | Promise<BaseDataResponse<number[]>>
    | PutEffect<ActionType<typeof getPayrollsAsync.request>>
    | PutEffect<ActionType<typeof getPeriodsAsync.failure>>
    | PutEffect<ActionType<typeof selectYear>>;

type GetPeriodSuccessGeneratorReturnType = PutEffect<ActionType<typeof getPayrollsAsync.request>> | void;

function* getPeriodSuccess(
    action: ReturnType<typeof getPeriodsAsync.success>
): Generator<GetPeriodSuccessGeneratorType, GetPeriodSuccessGeneratorReturnType, number> {
    // TODO: : Generator {
    try {
        console.warn('getPeriodSuccess');
        const years = action.payload;
        if (years.length === 0) {
            return;
        }

        const selectedYear = yield select(selectSelectedYear);
        const existPayrollsForSelectedYear = !!years.find(year => year === selectedYear);
        if (existPayrollsForSelectedYear) {
            // Rok exisutje nactu pro nej pasky - vyvolam akci
            yield put(getPayrollsAsync.request(selectedYear));
            return;
        }

        const currentYear = new Date().getFullYear();
        if (selectedYear === currentYear) {
            // Pro aktualni rok neni zadny zaznam ale pasky mohou byt pro rok predchozi, pri prechodu roku 2019 -> 2020
            // Aktualni je 2020 ale pasky mohou byt pro 2019

            const previousYear = currentYear - 1;
            const existPayrollsForPreviousYear = !!years.find(year => year === previousYear);
            if (existPayrollsForPreviousYear) {
                yield put(selectYear(previousYear));
                return;
            }
        }
    } catch (error) {
        yield put(getPeriodsAsync.failure(error));
    }
}

type GetPayrollsRequestGeneratorType =
    | Promise<BaseDataResponse<PayrollModel[]>>
    | PutEffect<ActionType<typeof getPayrollsAsync.success>>
    | PutEffect<ActionType<typeof getPayrollsAsync.failure>>;

function* getPayrollsRequest(
    action: ReturnType<typeof getPayrollsAsync.request>
): Generator<GetPayrollsRequestGeneratorType, void, BaseDataResponse<PayrollModel[]>> {
    // TODO: : Generator {
    try {
        console.warn('getPayrollsRequest');

        const response: BaseDataResponse<PayrollModel[]> = yield apiClientInstance.Payrolls.getAsync(action.payload);
        if (response.resultCode === 0) {
            yield put(getPayrollsAsync.success({ payrolls: payrollModelsToPayrollTicketMapper(response.data), year: action.payload }));
        } else {
            yield put(getPayrollsAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        yield put(getPayrollsAsync.failure(error));
    }
}

type SelectedYearChangedGeneratorType =
    | SelectEffect
    | PutEffect<ActionType<typeof getPayrollsAsync.request>>
    | PutEffect<ActionType<typeof getPayrollsAsync.failure>>;

function* selectedYearChanged(action: ReturnType<typeof selectYear>): Generator<SelectedYearChangedGeneratorType, void, PayrollTicket[]> {
    // TODO: : Generator {
    try {
        console.warn('selectedYearChanged');

        const payrolls: PayrollTicket[] = yield select(selectPayrolTicketsByYear(action.payload));
        if (payrolls.length === 0) {
            // Nejsou zadne listky zkusi je nacist
            yield put(getPayrollsAsync.request(action.payload));
        }
    } catch (error) {
        yield put(getPayrollsAsync.failure(error));
    }
}

type DownloadPayrollRequestGeneratorType =
    | SelectEffect
    | Promise<Blob | null>
    | PutEffect<ActionType<typeof downloadPayrollAsync.success>>
    | PutEffect<ActionType<typeof downloadFile>>
    | PutEffect<ActionType<typeof downloadPayrollAsync.failure>>;

function* downloadPayrollRequest(
    action: ReturnType<typeof downloadPayrollAsync.request>
): Generator<DownloadPayrollRequestGeneratorType, void, PayrollTicket & Blob> {
    // TODO: : Generator {
    try {
        console.warn('downloadPayrollRequest');

        const payroll: PayrollTicket = yield select(selectPayrollById(action.payload));
        const blob: Blob = yield apiClientInstance.Payrolls.getPdfAsync(action.payload);
        if (blob !== undefined) {
            const downloadFileResponse: DownloadFileResponse = {
                data: blob,
                filename: payroll.name
            };
            yield put(downloadPayrollAsync.success());
            yield put(downloadFile(downloadFileResponse));
        } else {
            yield put(downloadPayrollAsync.failure(new Error('Stažení souboru se nezdařilo.')));
        }
    } catch (error) {
        yield put(downloadPayrollAsync.failure(error));
    }
}

type GetPayrollPinCodeRequestGeneratorType =
    | Promise<BaseResponse>
    | PutEffect<ActionType<typeof getPayrollPinCodeAsync.success>>
    | PutEffect<ActionType<typeof getPayrollPinCodeAsync.failure>>;

function* getPayrollPinCodeRequest(
    action: ReturnType<typeof getPayrollPinCodeAsync.request>
): Generator<GetPayrollPinCodeRequestGeneratorType | ReturnType<typeof addSuccessAlertWithTenantFilter>, void, BaseResponse> {
    // TODO: : Generator {
    try {
        console.warn('getPayrollPinCodeRequest');

        const response: BaseResponse = yield apiClientInstance.Payrolls.getPdfPinCodeAsync(action.payload);
        if (response.resultCode === 0) {
            yield put(getPayrollPinCodeAsync.success());
            yield addSuccessAlertWithTenantFilter('Heslo bylo úspěšně odesláno.', 'genericSuccess');
        } else {
            yield put(getPayrollPinCodeAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        yield put(getPayrollPinCodeAsync.failure(error));
    }
}
