import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, RouterRootState } from 'connected-react-router';

import './utils/array';
import './icons/library';

import configureStore from './store/configureStore';
import { ApplicationState } from './store/rootReducer';
import ThemedAppContainer from './features/themes/themedAppContainer';
import './index.scss';
import { tenantProvider } from './features/tenant';

// Create browser history to use in the Redux store
// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const history = createBrowserHistory({ basename: baseUrl });
const history = createBrowserHistory();
const store = configureStore(history, {} as ApplicationState & RouterRootState);

const rootElement = document.getElementById('root');
const link = document.querySelector("link[rel~='icon']") as HTMLAnchorElement;
if (link) {
    const tenant = tenantProvider.getTenant();
    switch (tenant) {
        case 'cezep':
            {
                link.href = '/favicon.cezep.ico';
            }
            break;
        case 'lm':
            {
                link.href = '/favicon.lm.ico';
            }
            break;
    }
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemedAppContainer />
        </ConnectedRouter>
    </Provider>,
    rootElement
);
