import { ActionType } from 'typesafe-actions';
import uuidV4 from 'uuid/v4';

import * as actions from './actions';

export type AlertType = 'info' | 'success' | 'warning' | 'error';

export class AlertMessage {
    type: AlertType;
    message: string;
    timeout?: number;
    
    constructor(init: AlertMessage) {
        this.type = 'info';
        this.message = '';

        Object.assign(this, init);
    }
}

export class AlertModel extends AlertMessage {
    id: string;
    component?: React.ComponentType<any>;
    componentProps?: any;

    constructor(init: AlertMessage, component?: React.ComponentType<any>, componentProps?: any) {
        super(init);

        this.id = uuidV4();
        this.component = component;
        this.componentProps = componentProps;
    }
}

export type AlertsState = {
    alerts: AlertModel[];
};

export type AlertsAction = ActionType<typeof actions>;
