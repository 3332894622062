import { createSelector } from 'reselect';

import { ApplicationState } from '../../store/rootReducer';
import { DeepReadonly } from 'utility-types';
import Document from '../../models/document';
import { NormalizedByIdState } from '../../core';

const getDocumentsAllIds = (state: ApplicationState): number[] => state.documents.documentList.allIds;

const getDocumentsById = (state: ApplicationState): NormalizedByIdState<Document> => state.documents.documentList.byId;

export const selectDocumentById = (id: number) => (state: ApplicationState): Document => state.documents.documentList.byId[id];

export const selectDocuments = createSelector(
    [getDocumentsAllIds, getDocumentsById],
    (allIds, byId): DeepReadonly<Document[]> => {
        return allIds.map(id => byId[id]);
    }
);
