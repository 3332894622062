import { createSelector } from 'reselect';

import { ApplicationState } from '../../store/rootReducer';
import { DeepReadonly } from 'utility-types';
import { NormalizedByIdState } from '../../core';
import Document from '../../models/document';
import { ByGroupsState } from './types';
import { DocumentGroupModel } from '../../api/interfaces';

const getDocumentsAllIds = (state: ApplicationState): number[] => state.shiftSchedules.documentList.allIds;
const getDocumentsById = (state: ApplicationState): NormalizedByIdState<Document> => {
    return state.shiftSchedules.documentList.byId;
};
const getDocumentsSelectedGroupId = (state: ApplicationState): number => state.shiftSchedules.selectedGroupId;
const getDocumentsByGroups = (state: ApplicationState): ByGroupsState<Document> => state.shiftSchedules.documentList.byGroups;

export const selectDocumentById = (id: number) => (state: ApplicationState): Document => state.shiftSchedules.documentList.byId[id];

export const selectDocuments = createSelector(
    [getDocumentsAllIds, getDocumentsById],
    (allIds, byId): DeepReadonly<Document[]> => {
        return allIds.map(id => byId[id]);
    }
);

export const selectSelectedGroupId = getDocumentsSelectedGroupId;
export const selectDocumentsByGroup = (groupId: number) => (state: ApplicationState): DeepReadonly<Document[]> =>
    getDocumentsByGroups(state)[groupId] ? getDocumentsByGroups(state)[groupId] : [];

export const selectDocumentsBySelectedYear = createSelector(
    [getDocumentsByGroups, getDocumentsSelectedGroupId],
    (byGroups, selectedGroupId): DeepReadonly<Document[]> => {
        return byGroups[selectedGroupId] ? byGroups[selectedGroupId] : [];
    }
);
