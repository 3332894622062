import { createAction, createAsyncAction } from 'typesafe-actions';

import { DocumentGroupModel } from '../../api/interfaces';
import { ShiftSchedulesSuccessResponse } from './types';

export const selectGroup = createAction('shiftSchedules/SELECT_GROUP')<number>();

export const getGroupsAsync = createAsyncAction(
    'shiftSchedules/GET_GROUPS_REQUEST',
    'shiftSchedules/GET_GROUPS_SUCCESS',
    'shiftSchedules/GET_YGROUPS_FAILURE'
)<void, DocumentGroupModel[], Error>();

export const getDocumentsAsync = createAsyncAction(
    'shiftSchedules/GET_DOCUMENTS_REQUEST',
    'shiftSchedules/GET_DOCUMENTS_SUCCESS',
    'shiftSchedules/GET_DOCUMENTS_FAILURE'
)<number, ShiftSchedulesSuccessResponse, Error>();

export const downloadDocumentAsync = createAsyncAction(
    'shiftSchedules/DOWNLOAD_DOCUMENT_REQUEST',
    'shiftSchedules/DOWNLOAD_DOCUMENT_SUCCESS',
    'shiftSchedules/DOWNLOAD_DOCUMENT_FAILURE'
)<number, void, Error>();
