import React from 'react';
import { Typography, Paper, createStyles, Theme, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import DocumentsTable from './shiftSchedulesTable';
import * as actions from './actions';

import { selectDocuments } from './selectors';
import Document from '../../models/document';
import ShiftSchedulesYears from './shiftSchedulesGroups';
import { DocumentGroupModel } from '../../api/interfaces';
import { group } from 'console';

const withStylesFunction = withStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flex: "1" */
        },
        listRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none'
        },
        table: {},
        tableCellRoot: {
            borderBottomColor: theme.palette.listLine
        },
        tableCellBody: {
            color: theme.palette.text.secondary,
            padding: '0px 16px'
        },
        tableCellHead: {
            color: theme.palette.text.primary
        },
        tabsRoot: {
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        },
        tabRoot: {
            minWidth: '90px'
        },
        tabIndicator: {
            height: '3px'
        },
        tabTextColorPrimary: {
            '&.Mui-disabled': {
                color: theme.palette.primary.main
            },
            '&.Mui-selected': {
                color: theme.palette.text.primary
            }
        },
        infoTextRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none',
            padding: theme.spacing(2)
        },
        /* warningTextRoot: {
        marginTop: theme.spacing(2),           
    }, */
        infoTextActionIconRoot: {
            margin: '0px 5px'
        },
        infoTextActionIcon: {
            fontSize: '1.2rem'
        }
    })
);

type ShiftSchedulesContainerStateProps = DeepReadonly<{
    documents: Document[];
    groups: DocumentGroupModel[];
    selectedGroupId: number;
}>;

type ShiftSchedulesContainerDispatchProps = {
    getGroupsAction: typeof actions.getGroupsAsync.request;
    downloadDocumentAction: typeof actions.downloadDocumentAsync.request;
    selectGroup: typeof actions.selectGroup;
};

type ShiftSchedulesContainerProps = { classes: any } & ShiftSchedulesContainerStateProps & ShiftSchedulesContainerDispatchProps;

type ShiftSchedulesContainerClassKey = 'root';

class ShiftSchedulesContainer extends React.Component<ShiftSchedulesContainerProps> {
    componentDidMount(): void {
        this.props.getGroupsAction();
    }

    render(): React.ReactElement {
        return (
            <div className={this.props.classes.root}>
                <Typography variant="h4" color="primary">
                    Harmonogramy směn
                </Typography>

                <Paper className={this.props.classes.listRoot} aria-label="list of shift schedules">
                    <ShiftSchedulesYears
                        groups={this.props.groups}
                        selectedGroupId={this.props.selectedGroupId}
                        onSelectedYearChange={this.props.selectGroup}
                    />
                    <DocumentsTable rows={this.props.documents} downloadDocumentAction={this.props.downloadDocumentAction} />
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ShiftSchedulesContainerStateProps => {
    return {
        documents: selectDocuments(state),
        groups: state.shiftSchedules.groups,
        selectedGroupId: state.shiftSchedules.selectedGroupId
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ShiftSchedulesContainerDispatchProps =>
    bindActionCreators(
        {
            getGroupsAction: actions.getGroupsAsync.request,
            downloadDocumentAction: actions.downloadDocumentAsync.request,
            selectGroup: actions.selectGroup
        },
        dispatch
    );

export default withStylesFunction(connect(mapStateToProps, mapDispatchToProps)(ShiftSchedulesContainer));
