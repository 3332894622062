import { AxiosInstance } from 'axios';

import { BaseClient } from '.';
import { BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';

export default class AuthenticationClient extends BaseClient {
    constructor(errorHandler: IErrorHandler,axiosInstance: AxiosInstance) {
        super(errorHandler,axiosInstance, 'auth');
    }

    public async sendOtpAsync(identificationNumber: string): Promise<BaseResponse> {
        const data = { identificationNumber: identificationNumber };

        return await this.postHttpAsync<BaseResponse, typeof data>('send-otp', data);
    }

    public async loginAsync(identificationNumber: string, otp: string): Promise<BaseResponse> {
        const data = { identificationNumber: identificationNumber, otp: otp };

        return await this.postHttpAsync<BaseResponse, typeof data>('login-otp', data);
    }

    public async signOut(): Promise<BaseResponse> {
        const data = {};

        return await this.postHttpAsync<BaseResponse, typeof data>('sign-out', data);
    }
}
