import { MenuItemsConfiguration } from '../../configuration/mainMenu';
import { tenantProvider } from '../../features/tenant';
import { MenuItemModel } from './types';

class MenuProvider {
    getMenu = (): MenuItemModel[] => {
        const tenantKey = tenantProvider.getTenant();

        return MenuItemsConfiguration.filter((menuItem: MenuItemModel) => {
            if (!menuItem.onlyForTenants) {
                return true;
            }
            
            const isMenuItemForCurrentTenant = menuItem.onlyForTenants.includes(tenantKey);

            return isMenuItemForCurrentTenant;
        });
    };
}

export default new MenuProvider();
