import React from 'react';
import { Collapse } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { AlertModel } from './types';
import { alertAutoCloseConfig } from '../../configuration/alerts';

const initialState = { isAlertVisible: true };

/* type AlertsContainerClassKey = 'root' | 'closeButton';
 */
type AlertState = Readonly<typeof initialState>;

type AlertProps = {
    alert: AlertModel;
    autoCloseConfig: typeof alertAutoCloseConfig;
    canClose: boolean;
    /*     classes: ClassNameMap<AlertsContainerClassKey>;
     */
    onClose(id: string): void;
};

class Alert extends React.Component<AlertProps, AlertState> {
    timer: NodeJS.Timeout | undefined;

    static defaultProps = {
        autoCloseConfig: alertAutoCloseConfig,
        canClose: true
    };

    readonly state: AlertState = initialState;

    constructor(props: AlertProps) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount(): void {
        const isAlertTimeout = this.props.alert.timeout && this.props.alert.timeout > 0;

        if (
            (this.props.alert.type === 'error' && this.props.autoCloseConfig.autoCloseError) ||
            (this.props.alert.type === 'info' && this.props.autoCloseConfig.autoCloseInfo) ||
            (this.props.alert.type === 'warning' && this.props.autoCloseConfig.autoCloseWarning) ||
            (this.props.alert.type === 'success' && this.props.autoCloseConfig.autoCloseSuccess)
        ) {
            this.timer = setTimeout(this.onHide, isAlertTimeout ? (this.props.alert.timeout as number) : this.props.autoCloseConfig.timeout);
        }
    }

    componentWillUnmount(): void {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    onHide(): void {
        this.setState({ isAlertVisible: false });
    }

    onClose(): void {
        this.props.onClose(this.props.alert.id);
    }

    render(): React.ReactElement {
        const alert = this.props.alert;

        return (
            <Collapse in={this.state.isAlertVisible} unmountOnExit onExited={this.onClose}>
                <MuiAlert variant="outlined" severity={alert.type} onClose={this.onHide}>
                    {this.props.alert.component ? <this.props.alert.component {...this.props.alert.componentProps} /> : this.props.alert.message}
                </MuiAlert>
            </Collapse>
        );
    }
}

/* export default withStylesFunction(Alert);
 */

export default Alert;
