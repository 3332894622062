import React from 'react';
import { makeStyles, createStyles, Theme, Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';

import { TabsProps } from './types';
import { isOfType } from 'typesafe-actions';
import { number, string } from 'yup';
import { strict } from 'assert';

export const useTabsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        image: {
            maxWidth: '100%'
        },
        tabsRoot: {
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        },
        tabRoot: {
            minWidth: '90px'
        },
        tabIndicator: {
            height: '3px'
        },
        tabTextColorPrimary: {
            '&.Mui-disabled': {
                color: theme.palette.primary.main
            },
            '&.Mui-selected': {
                color: theme.palette.text.primary
            }
        }
    })
);

const Tabs = <TTabItemType extends string | number | {}>(props: TabsProps<TTabItemType>) => {
    const classes = useTabsStyles();
    const tabOffset = 1; // Prvni tab je popisek

    const tabItemKeySelector = props.tabItemKeySelector
        ? props.tabItemKeySelector
        : (tabItem: TTabItemType) => {
              if (typeof tabItem === 'number') {
                  return tabItem as number;
              } else if (typeof tabItem === 'string') {
                  return tabItem as string;
              } else {
                  throw new Error('Unsupported type of defaul tab item key selector');
              }
          };

    const tabItemLabelSelector = props.tabItemLabelSelector
        ? props.tabItemLabelSelector
        : (tabItem: TTabItemType) => {
              if (typeof tabItem === 'number') {
                  return tabItem as number;
              } else if (typeof tabItem === 'string') {
                  return tabItem as string;
              } else {
                  throw new Error('Unsupported type of defaul tab item key selector');
              }
          };

    const handleChange = (event: React.ChangeEvent<{}>, selectedTabValue: number): void => {
        event.preventDefault();
        const itemKey = selectedTabValue;
        props.onSelectedItemChange(itemKey);

        console.warn('Tabs changed', selectedTabValue);
    };
    /* const handleChange = (event: React.ChangeEvent<{}>, selectedTabIndex: number): void => {
        event.preventDefault();
        const itemKey = tabItemKeySelector(props.tabItems[selectedTabIndex - tabOffset]);
        props.onSelectedItemChange(itemKey);

        console.warn('Tabs changed', selectedTabIndex);
    }; */

    /* const selectedTabIndex = props.tabItems.indexOf(props.selectedTabItemKey) + tabOffset;

    console.warn('Tabs render', selectedTabIndex); */

    return (
        <MuiTabs
            /* value={selectedTabIndex} */
            value={props.selectedTabItemKey}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="tabs with items"
            classes={{ root: classes.tabsRoot, indicator: classes.tabIndicator }}
        >
            <MuiTab
                label={props.labelTab}
                disabled
                classes={{
                    root: classes.tabRoot,
                    textColorPrimary: classes.tabTextColorPrimary
                }}
                key={props.labelTab}
            />
            {props.tabItems.map(tabItem => (
                <MuiTab
                    label={tabItemLabelSelector(tabItem)}
                    classes={{
                        root: classes.tabRoot,
                        textColorPrimary: classes.tabTextColorPrimary
                    }}
                    value={tabItemKeySelector(tabItem)}
                    key={tabItemKeySelector(tabItem)}
                />
            ))}
        </MuiTabs>
    );
};

export default Tabs;
