import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState, LocationChangeAction } from 'connected-react-router';
import { History } from 'history';

import { AuthenticationState, AuthenticationAction, authenticationReducer, authenticationInitialState } from '../features/authentication';
import { SignInState, SignInAction, signInReducer, signInInitialState } from '../features/signIn';
import { PayrollTicketsState, PayrollTicketsAction, payrollTicketsReducer, payrollInitialState } from '../features/payrollTickets';
import { ThemeState, themeReducer, themeInitialState } from '../features/themes';
import {
    FullPageLoadingIndicatorState,
    FullPageLoadingIndicatorAction,
    fullPageLoadingIndicatorReducer,
    fullPageLoadingIndicatorInitialState
} from '../components/fullPageLoadingIndicator';
import { SignOutAction } from '../features/signOut';
import { ErrorsState, ErrorsAction, errorsInitialState, errorsReducer } from '../features/errors';
import { AlertsState, AlertsAction, alertsInitialState, alertsReducer } from '../features/alerts';
import { DocumentsState, DocumentsAction, documentsInitialState, documentsReducer } from '../features/documents';
import { ShiftSchedulesState, ShiftSchedulesAction, shiftSchedulesInitialState, shiftSchedulesReducer } from '../features/shiftSchedules';

export type ApplicationState = {
    authentication: AuthenticationState;
    signIn: SignInState;
    payrollTickets: PayrollTicketsState;
    theme: ThemeState;
    fullPageLoadingIndicator: FullPageLoadingIndicatorState;
    router: RouterState;
    errors: ErrorsState;
    alerts: AlertsState;
    documents: DocumentsState;
    shiftSchedules: ShiftSchedulesState;
};

/* export type ApplicationAction = AuthenticationAction &
    SignInAction &
    PayrollTicketsAction &
    SignOutAction &
    FullPageLoadingIndicatorAction &
    LocationChangeAction &
    ErrorsAction &
    AlertsAction &
    DocumentsAction &
    ShiftSchedulesAction; */

/* const rootReducer = {
    authentication: authenticationReducer,
    signIn: signInReducer,
    payrollTickets: payrollTicketsReducer,
    theme: themeReducer,
    fullPageLoadingIndicator: fullPageLoadingIndicatorReducer
} as ReducersMapObject<ApplicationState, ApplicationAction>;

export default rootReducer; */

const initialState: ApplicationState = {
    authentication: authenticationInitialState,
    signIn: signInInitialState,
    payrollTickets: payrollInitialState,
    theme: themeInitialState,
    fullPageLoadingIndicator: fullPageLoadingIndicatorInitialState,
    router: {} as RouterState,
    errors: errorsInitialState,
    alerts: alertsInitialState,
    documents: documentsInitialState,
    shiftSchedules: shiftSchedulesInitialState
};

const appReducer = (history: History): Reducer<ApplicationState> =>
    combineReducers<ApplicationState>({
        authentication: authenticationReducer,
        signIn: signInReducer,
        payrollTickets: payrollTicketsReducer,
        theme: themeReducer,
        fullPageLoadingIndicator: fullPageLoadingIndicatorReducer,
        router: connectRouter(history),
        errors: errorsReducer,
        alerts: alertsReducer,
        documents: documentsReducer,
        shiftSchedules: shiftSchedulesReducer
    });

/* const rootReducer = createReducer<ApplicationState, ApplicationAction>(initialState, {
     ...rootSignOutReducer.handlers,
    signIn: signInReducer,
    authentication: authenticationReducer,
    payrollTickets: payrollTicketsReducer,
    theme: themeReducer,
    fullPageLoadingIndicator: fullPageLoadingIndicatorReducer
}); */

/* const a = combineReducers<ApplicationState, ApplicationAction>({
    authentication: authenticationReducer,
    signIn: signInReducer,
    payrollTickets: payrollTicketsReducer,
    theme: themeReducer,
    fullPageLoadingIndicator: fullPageLoadingIndicatorReducer
});
 */

// TODO: no any
const rootReducer = (history: History) => (state: any, action: any) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'signOut/SIGN_OUT_SUCCESS') {
        // we keep a reference of the keys we want to maintain
        // other keys will be passed as undefined and this will call
        // reducers with an initial state

        state = { ...initialState, router: state.router, theme: state.theme };
    }

    return appReducer(history)(state, action);
};

export default rootReducer;

/* 
const pageReducer = createReducer<DeepReadonly<SignInPageEnum>, SignInAction>(initialState.page)
    .handleAction(signInRequestOptAsync.success, (state, action) => SignInPageEnum.PinCode)
    .handleAction(signInRequestOptAsync.failure, (state, action) => SignInPageEnum.PersonalId);

const personalIdReducer = createReducer<DeepReadonly<string>, SignInAction>(initialState.personalId)
    .handleAction(signInRequestOptAsync.request, (state, action) => initialState.personalId)
    .handleAction(signInRequestOptAsync.success, (state, action) => action.payload)
    .handleAction(signInRequestOptAsync.failure, (state, action) => initialState.personalId);

const errorMessageReducer = createReducer<DeepReadonly<string>, SignInAction>(initialState.errorMessage)
    .handleAction(
        [signInValidateOptAsync.request, signInValidateOptAsync.success, signInRequestOptAsync.request, signInRequestOptAsync.success],
        (state, action) => initialState.errorMessage
    )
    .handleAction([signInValidateOptAsync.failure, signInRequestOptAsync.failure], (state, action) => action.payload.message);

const requestInProgressReducer = createReducer<DeepReadonly<boolean>, SignInAction>(initialState.requestInProgress)
    .handleAction([signInValidateOptAsync.request, signInRequestOptAsync.request], (state, action) => true)
    .handleAction(
        [signInValidateOptAsync.success, signInRequestOptAsync.success, signInValidateOptAsync.failure, signInRequestOptAsync.failure],
        (state, action) => initialState.requestInProgress
    ); */

/* export default combineReducers<SignInState, SignInAction>({
    page: pageReducer,
    personalId: personalIdReducer,
    errorMessage: errorMessageReducer,
    requestInProgress: requestInProgressReducer
}); */
