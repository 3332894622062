import { AxiosInstance } from 'axios';

import { BaseResponse } from '../responses';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';
import BaseJsonClient from './baseJsonClient';

export default class BaseAuthenticatedClient extends BaseJsonClient {
    protected readonly authenticatedRequestHandler: IAuthenticatedRequestHandler;
    protected readonly errorHandler: IErrorHandler;

    constructor(
        authenticatedRequestHandler: IAuthenticatedRequestHandler,
        errorHandler: IErrorHandler,
        axiosInstance: AxiosInstance,
        modelPath: string
    ) {
        super(axiosInstance, modelPath);

        this.authenticatedRequestHandler = authenticatedRequestHandler;
        this.errorHandler = errorHandler;
    }

    protected async getHttpAsync<TResponse extends BaseResponse>(route: string, query: string): Promise<TResponse> {
        try {
            return this.authenticatedRequestHandler.handleRequestAsync(async () => {
                return await super.getHttpAsync<TResponse>(route, query);
            });
        } catch (error) {
            const errorResposne = new BaseResponse();
            errorResposne.resultCode = -1;
            errorResposne.resultReason = error.message;

            return errorResposne as TResponse;
        }
    }

    protected async getHttpBinaryAsync(route: string, query: string): Promise<Blob | null> {
        try {
            const config = {
                responseType: 'blob' as 'blob',
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const axiosResponse = await this.axiosInstance.get(this.composeUrl(route, query), config);
            if (axiosResponse.status === 500) {
                return null;
            }

            console.warn(axiosResponse);

            return axiosResponse.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    protected async putHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
        try {
            return this.authenticatedRequestHandler.handleRequestAsync(async () => {
                return await super.putHttpAsync<TResponse, TData>(route, data);
            });
        } catch (error) {
            const errorResposne = new BaseResponse();
            errorResposne.resultCode = -1;
            errorResposne.resultReason = error.message;

            return errorResposne as TResponse;
        }
    }

    protected async postHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
        try {
            return this.authenticatedRequestHandler.handleRequestAsync(async () => {
                return await super.postHttpAsync<TResponse, TData>(route, data);
            });
        } catch (error) {
            const errorResposne = new BaseResponse();
            errorResposne.resultCode = -1;
            errorResposne.resultReason = error.message;

            return errorResposne as TResponse;
        }
    }
}
