import axios, { AxiosInstance } from 'axios';

import { PayrollClient, AuthenticationClient, DocumentClient, DocumentGroupClient } from './clients';
import { AuthenticatedRequestHandler, ErrorHandler } from './handlers';
import { tenantInterceptor } from './interceptors';

export class ApiClient {
    private axiosInstance: AxiosInstance;

    constructor(baseUrl: string) {
        this.axiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: 300000,
            withCredentials: true,
            headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
                //ClientName: clientName,
                //TimeZoneIana: this.getTimeZoneIana()
            },
            validateStatus: (status: number) => true
        });

        this.registerInterceptors();

        const authenticatedRequestHandler = new AuthenticatedRequestHandler();
        const errorHandler = new ErrorHandler();

        this.Payrolls = new PayrollClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
        this.Documents = new DocumentClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
        this.DocumentGroups = new DocumentGroupClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
        this.Authentication = new AuthenticationClient(errorHandler, this.axiosInstance);
    }

    public Authentication: AuthenticationClient;

    public Payrolls: PayrollClient;

    public Documents: DocumentClient;

    public DocumentGroups: DocumentGroupClient;

    private registerInterceptors(): void {
        this.axiosInstance.interceptors.request.use(tenantInterceptor, error => {
            return Promise.reject(error);
        });
        /*  this.axiosInstance.interceptors.response.use(
            response => stringToDateInterceptor.execute(response),
            error => {
                return Promise.reject(error);
            }
        );
      */
    }
}
