import React, { useEffect } from 'react';
import { Typography, Paper, makeStyles, createStyles, Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import PayrollTicketsYears from './payrollTicketsYears';
import PayrollTicketsTable from './payrollTicketsTable';
import { PayrollTicket } from './types';
import * as actions from './actions';

import { selectPayrolTicketsBySelectedYear } from './selectors';
import { Icon } from '../../icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flex: "1" */
        },
        listRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none'
        },
        table: {},
        tableCellRoot: {
            borderBottomColor: theme.palette.listLine
        },
        tableCellBody: {
            color: theme.palette.text.secondary,
            padding: '0px 16px'
        },
        tableCellHead: {
            color: theme.palette.text.primary
        },
        tabsRoot: {
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        },
        tabRoot: {
            minWidth: '90px'
        },
        tabIndicator: {
            height: '3px'
        },
        tabTextColorPrimary: {
            '&.Mui-disabled': {
                color: theme.palette.primary.main
            },
            '&.Mui-selected': {
                color: theme.palette.text.primary
            }
        },
        infoTextRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none',
            padding: theme.spacing(2)
        },
        /* warningTextRoot: {
            marginTop: theme.spacing(2),           
        }, */
        infoTextActionIconRoot: {
            margin: '0px 5px'
        },
        infoTextActionIcon: {
            fontSize: '1.2rem'
        }
    })
);

type PayrollTicketsContainerStateProps = DeepReadonly<{
    years: number[];
    payrollTickets: PayrollTicket[];
    selectedYear: number;
}>;

type PayrollTicketsContainerDispatchProps = {
    selectYear: typeof actions.selectYear;
    getPeriodsRequest: typeof actions.getPeriodsAsync.request;
    downloadPayrollAction: typeof actions.downloadPayrollAsync.request;
    getPayrollPinCodeAction: typeof actions.getPayrollPinCodeAsync.request;
};

type PayrollTicketsContainerProps = {} & PayrollTicketsContainerStateProps & PayrollTicketsContainerDispatchProps;

const PayrollTicketsContainer: React.FunctionComponent<PayrollTicketsContainerProps> = props => {
    console.warn('PayrollTicketsContainer');
    const classes = useStyles();
    const getPeriodsRequest = props.getPeriodsRequest;

    useEffect(() => {
        console.warn('PayrollTicketsContainer.useEffect');

        getPeriodsRequest();
    }, [getPeriodsRequest]);

    return (
        <div className={classes.root}>
            <Typography variant="h4" color="primary">
                Výplatní lístky
            </Typography>
            <Paper className={classes.infoTextRoot}>
                <Typography variant="body1" color="textPrimary">
                    Pro otevření staženého
                    {
                        <Icon
                            iconName="download"
                            className={classes.infoTextActionIcon}
                            IconProps={{
                                classes: { root: classes.infoTextActionIconRoot },
                                color: 'primary',
                                title: 'Stáhnout'
                            }}
                        />
                    }
                    výplatního lístku je třeba zadat heslo, které si odešlete pomocí akce
                    {
                        <Icon
                            iconName="key"
                            className={classes.infoTextActionIcon}
                            IconProps={{
                                classes: { root: classes.infoTextActionIconRoot },
                                color: 'primary',
                                title: 'Odeslat heslo'
                            }}
                        />
                    }
                    na příslušném řádku. Heslo Vám bude doručen na Vaše mobilní číslo. Doručené heslo zadáte do formuláře v PDF.
                </Typography>
            </Paper>          

            <Paper className={classes.listRoot} aria-label="list of payroll tickets">
                <PayrollTicketsYears years={props.years} selectedYear={props.selectedYear} onSelectedYearChange={props.selectYear} />
                <PayrollTicketsTable
                    rows={props.payrollTickets}
                    downloadPayrollAction={props.downloadPayrollAction}
                    getPayrollPinCode={props.getPayrollPinCodeAction}
                />
            </Paper>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState): PayrollTicketsContainerStateProps => {
    return {
        years: state.payrollTickets.years,
        payrollTickets: selectPayrolTicketsBySelectedYear(state),
        selectedYear: state.payrollTickets.selectedYear
    };
};

const mapDispatchToProps = (dispatch: Dispatch): PayrollTicketsContainerDispatchProps =>
    bindActionCreators(
        {
            selectYear: actions.selectYear,
            getPeriodsRequest: actions.getPeriodsAsync.request,
            downloadPayrollAction: actions.downloadPayrollAsync.request,
            getPayrollPinCodeAction: actions.getPayrollPinCodeAsync.request,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTicketsContainer);
