import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getDocumentsAsync, downloadDocumentAsync, getGroupsAsync, selectGroup } from './actions';
import apiClientInstance from '../../api/apiClientInstance';
import { DocumentGroupModel, DocumentGroupType, DocumentModel, DocumentType } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { selectDocumentById, selectDocumentsByGroup, selectSelectedGroupId } from './selectors';
import Document from '../../models/document';
import { DownloadFileResponse } from '../downloadFile';
import { downloadFile } from '../downloadFile/actions';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([
        yield takeEvery(getGroupsAsync.request, getGroupsRequest),
        yield takeEvery(getGroupsAsync.success, getGroupsSuccess),
        yield takeEvery(getDocumentsAsync.request, getDocumentsRequest),
        yield takeEvery(downloadDocumentAsync.request, downloadDocumentRequest),
        yield takeEvery(selectGroup, selectedGroupChanged)
    ]);
}

type GetDocumentsRequestGeneratorType =
    | Promise<BaseDataResponse<DocumentModel[]>>
    | PutEffect<ActionType<typeof getDocumentsAsync.success>>
    | PutEffect<ActionType<typeof getDocumentsAsync.failure>>;

function* getDocumentsRequest(
    action: ReturnType<typeof getDocumentsAsync.request>
): Generator<GetDocumentsRequestGeneratorType, void, BaseDataResponse<DocumentModel[]>> {
    // TODO: : Generator {
    try {
        console.warn('getDocumentsRequest');

        const response: BaseDataResponse<DocumentModel[]> = yield apiClientInstance.Documents.getByGroupAsync(action.payload);
        if (response.resultCode === 0) {
            yield put(getDocumentsAsync.success({ documents: response.data, groupId: action.payload }));
        } else {
            yield put(getDocumentsAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        yield put(getDocumentsAsync.failure(error));
    }
}

type DownloadDocumentRequestGeneratorType =
    | SelectEffect
    | Promise<Blob | null>
    | PutEffect<ActionType<typeof downloadDocumentAsync.success>>
    | PutEffect<ActionType<typeof downloadFile>>
    | PutEffect<ActionType<typeof downloadDocumentAsync.failure>>;

function* downloadDocumentRequest(
    action: ReturnType<typeof downloadDocumentAsync.request>
): Generator<DownloadDocumentRequestGeneratorType, void, Document & Blob> {
    // TODO: : Generator {
    try {
        console.warn('downloadDocumentRequest');

        const payroll: Document = yield select(selectDocumentById(action.payload));
        const blob: Blob = yield apiClientInstance.Documents.getPdfAsync(action.payload);

        if (blob !== undefined) {
            const downloadFileResponse: DownloadFileResponse = {
                data: blob,
                filename: payroll.fileName
            };
            yield put(downloadDocumentAsync.success());
            yield put(downloadFile(downloadFileResponse));
        } else {
            yield put(downloadDocumentAsync.failure(new Error('Stažení souboru se nezdařilo.')));
        }
    } catch (error) {
        yield put(downloadDocumentAsync.failure(error));
    }
}

type SelectedGroupChangedGeneratorType =
    | SelectEffect
    | PutEffect<ActionType<typeof getDocumentsAsync.request>>
    | PutEffect<ActionType<typeof getDocumentsAsync.failure>>;

function* selectedGroupChanged(action: ReturnType<typeof selectGroup>): Generator<SelectedGroupChangedGeneratorType, void, Document[]> {
    // TODO: : Generator {
    try {
        console.warn('selectedGroupChanged');

        const documents: Document[] = yield select(selectDocumentsByGroup(action.payload));
        if (documents.length === 0) {
            // Nejsou zadne listky zkusi je nacist
            yield put(getDocumentsAsync.request(action.payload));
        }
    } catch (error) {
        yield put(getDocumentsAsync.failure(error));
    }
}

type GetPeriodsRequestGeneratorType =
    | Promise<BaseDataResponse<DocumentGroupModel[]>>
    | PutEffect<ActionType<typeof getGroupsAsync.success>>
    | PutEffect<ActionType<typeof getGroupsAsync.failure>>
    | PutEffect<ActionType<typeof selectGroup>>;

function* getGroupsRequest(
    action: ReturnType<typeof getGroupsAsync.request>
): Generator<GetPeriodsRequestGeneratorType, void, BaseDataResponse<DocumentGroupModel[]>> {
    // TODO: : Generator {
    try {
        console.warn('getGroupsRequest');
        const response: BaseDataResponse<DocumentGroupModel[]> = yield apiClientInstance.DocumentGroups.getAsync(DocumentGroupType.ShiftSchedule);
        if (response.resultCode === 0) {
            const orderedData = response.data.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
            yield put(getGroupsAsync.success(orderedData));

            if (orderedData.length > 0) {
                yield put(selectGroup(orderedData[0].id));
            }
        } else {
            yield put(getGroupsAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        yield put(getGroupsAsync.failure(error));
    }
}

type GetPeriodSuccessGeneratorType =
    | SelectEffect
    | Promise<BaseDataResponse<number[]>>
    | PutEffect<ActionType<typeof getDocumentsAsync.request>>
    | PutEffect<ActionType<typeof getGroupsAsync.failure>>
    | PutEffect<ActionType<typeof selectGroup>>;

type GetPeriodSuccessGeneratorReturnType = PutEffect<ActionType<typeof getDocumentsAsync.request>> | void;

function* getGroupsSuccess(
    action: ReturnType<typeof getGroupsAsync.success>
): Generator<GetPeriodSuccessGeneratorType, GetPeriodSuccessGeneratorReturnType, number> {
    // TODO: : Generator {
    try {
        console.warn('getGroupsSuccess');
        const groups = action.payload;
        if (groups.length === 0) {
            return;
        }

        const selectedGroup = yield select(selectSelectedGroupId);
        const existDocumentsForSelectedGroup = !!groups.find(group => group.id === selectedGroup);
        if (existDocumentsForSelectedGroup) {
            yield put(getDocumentsAsync.request(selectedGroup));
            return;
        }
    } catch (error) {
        yield put(getGroupsAsync.failure(error));
    }
}
