import { Tenants, tenantProvider } from '../tenant';
import { NotificationConfiguration, NotificationTypes } from './types';

const notificationConfigurations = {
    cezep: { newDocument: true, newSection: true, accesToPortalAfterTerminationOfEmployment: true }
} as Record<Tenants, NotificationConfiguration>;

export const isNotificationAllowed = (notificationType: NotificationTypes): boolean => {
    if (notificationType === 'genericError' || notificationType === 'genericSuccess') {
        return true;
    }

    const tenant = tenantProvider.getTenant();
    const configuration = notificationConfigurations[tenant];

    if (!configuration) {
        return false;
    }

    const notificationTypecCnfiguration = configuration[notificationType];

    return notificationTypecCnfiguration;
};
