import { createAction } from 'typesafe-actions';
import { put } from 'redux-saga/effects';

import { AlertModel, AlertMessage } from './types';
import { NotificationTypes } from '../notifications/types';
import { isNotificationAllowed } from '../notifications';

export const addAlert = createAction('alert/ADD_ALERT')<AlertModel>();

export const removeAlert = createAction('alert/REMOVE_ERROR')<string>();

export const clearAlerts = createAction('alert/CLEAR_ALERTS')();

const addErrorAlert = (message: string): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'error' }));

    return addAlert(alert);
};

const addSuccessAlert = (message: string): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'success' }));

    return addAlert(alert);
};

const addInfoAlerWithCustomComponent = (message: string, component: React.ComponentType<any>, componentProps: any): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'info', timeout: 30000 }), component, componentProps);

    return addAlert(alert);
};

const addErrorAlerWithCustomComponent = (message: string, component: React.ComponentType<any>, componentProps: any): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'error' }), component, componentProps);

    return addAlert(alert);
};
