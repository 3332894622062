import React from 'react';
import { withStyles, Theme } from '@material-ui/core';
import { ClassNameMap, StyleRules } from '@material-ui/core/styles/withStyles';

type LogoClassKey = 'root';

type LogoProps = {
    imageSrc: string;
    alt: string;
    classes: ClassNameMap<LogoClassKey>;
};

const styles = (theme: Theme): StyleRules<LogoClassKey> => ({
    root: {
        height: 32,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const Logo: React.FunctionComponent<LogoProps> = (props): React.ReactElement => {
    return <img src={props.imageSrc} className={props.classes.root} alt={props.alt} />;
};

export default withStyles(styles)(Logo);
