import React from 'react';
import { Typography, Grid, Box, makeStyles, Theme, StyleRules, createStyles } from '@material-ui/core';

const useStyles = makeStyles(
    (theme: Theme): StyleRules<NewDocumentNotificationClassKey> =>
        createStyles({
            root: {
                '& a': {
                    color: 'inherit'
                }
            }
        })
);

type NewDocumentNotificationClassKey = 'root';

type NewDocumentNotificationProps = {
    sectionName: string;
    documentDescription: string;
    sectionPath: string;
};

const NewDocumentNotification = (props: NewDocumentNotificationProps): React.ReactElement => {
    const classes = useStyles();

    return (
        <Grid container classes={{ root: classes.root }}>
            {'Upozornění: '}{props.documentDescription}
            <Box ml={0.5}>
                <Typography variant="subtitle2" color="primary">
                    <a href={props.sectionPath}>{props.sectionName}</a>
                </Typography>
            </Box>
            {'. '}
            <Box ml={0.5}>
                <a href={props.sectionPath}> Přejít do sekce</a>
            </Box>
        </Grid>
    );
};

export default NewDocumentNotification;
