import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { LocationState, Path } from 'history';

import { afterAuthenticatedAppDidMount, afterNavigation } from './actions';
import { addErrorAlertWithTenantFilter, addInfoAlerWithCustomComponentWithTenantFilter } from '../alerts';
import { getUserExpiryDate } from '../authentication';
import { NewSectionNotification, NewDocumentNotification, getAccesToPortalAfterTerminationOfEmployment } from '../notifications';
import { getUserTenantDomain } from '../authentication/selectors';
import { tenantProvider, TenantDomains } from '../tenant';


export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([yield takeEvery(afterAuthenticatedAppDidMount, afterAuthenticatedAppDidMountHandler)]);
    yield all([yield takeEvery(afterNavigation, afterNavigationHandler)]);
}

function* afterAuthenticatedAppDidMountHandler(
    action: ReturnType<typeof afterAuthenticatedAppDidMount>
): Generator<
    | SelectEffect
    | PutEffect<ActionType<typeof addErrorAlertWithTenantFilter>>
    | ReturnType<typeof addErrorAlertWithTenantFilter>
    | ReturnType<typeof addInfoAlerWithCustomComponentWithTenantFilter>,
    void,
    Date | null
> {
    const userExpiryDate = yield select(getUserExpiryDate);
    const message = getAccesToPortalAfterTerminationOfEmployment(userExpiryDate);

    yield addErrorAlertWithTenantFilter(message, 'accesToPortalAfterTerminationOfEmployment');
    yield addInfoAlerWithCustomComponentWithTenantFilter(
        message,
        NewSectionNotification,
        {
            sectionName: 'Informace pro zaměstnance',
            sectionPath: '/documents',
            sectionDescription: 'Naleznete tam důležité dokumenty'
        },
        'newSection'
    );
}

function* afterNavigationHandler(
    action: ReturnType<typeof afterNavigation>
): Generator<
    | SelectEffect
    | PutEffect<CallHistoryMethodAction<[ Path, LocationState? ]>>
    | ReturnType<typeof addErrorAlertWithTenantFilter>
    | ReturnType<typeof addInfoAlerWithCustomComponentWithTenantFilter>,
    void,
    string | null
> {
    const userTenantDomain = yield select(getUserTenantDomain);
    const currentTenantDomain = tenantProvider.getSubdomain();
    if (!userTenantDomain) {
        yield put(push("/signout"));
        return;
    }

    const currentTenant = TenantDomains[currentTenantDomain];
    const userTenant = TenantDomains[userTenantDomain];

    if (userTenant !== currentTenant) {
        yield put(push("/signout"));
        return;
    }
}
