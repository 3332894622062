import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { default as defaultPalette } from './palette.default';
import { default as cezepPalette } from './palette.cezep';
import { default as lmPalette } from './palette.lm';
import { default as ksystemPalette } from './palette.ksystem';

import { ThemaName } from '../types';

const ColorPalettes = {
    default: defaultPalette,
    cezep: cezepPalette,
    ksystem: ksystemPalette,
    lm: lmPalette
} as Record<ThemaName, PaletteOptions>;

export default ColorPalettes;

export { default as PaletteProvider } from './paletteProvider';
