import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

/* import { Newses } from '../newses'; */
import { PayrollTicketsContainer } from '../payrollTickets';
import { PayrollTicketPatternContainer } from '../payrollTicketPattern';
import { SignOutContainer } from '../signOut';
import { DocumentsContainer } from '../documents';
import { ShiftSchedulesContainer } from '../shiftSchedules';
import { afterNavigation } from './actions';

type AuthenticatedAppRouterProps = {};

const AuthenticatedAppRouter: React.FunctionComponent<AuthenticatedAppRouterProps> = (): React.ReactElement => {
    console.warn('AuthenticatedAppRouter');
    
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        console.debug('AuthenticatedAppRouter.useEffect');

        dispatch(afterNavigation());
    }, [location.pathname, dispatch]);

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/payrolltickets" />;
            </Route>
            <Route path="/payrolltickets">
                <PayrollTicketsContainer />
            </Route>
            <Route path="/payrollticketpattern">
                <PayrollTicketPatternContainer />
            </Route>
            <Route path="/documents">
                <DocumentsContainer />
            </Route>
            <Route path="/shiftSchedules">
                <ShiftSchedulesContainer />
            </Route>
            <Route path="/signout">
                <SignOutContainer />
            </Route>
            <Route path="*">
                <Redirect to="/payrolltickets" />
            </Route>
        </Switch>
    );
};

export default AuthenticatedAppRouter;
