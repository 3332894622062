import { createAction, createAsyncAction } from 'typesafe-actions';

import { DocumentModel } from '../../api/interfaces';

export const getDocumentsAsync = createAsyncAction(
    'documents/GET_DOCUMENTS_REQUEST',
    'documents/GET_DOCUMENTS_SUCCESS',
    'documents/GET_DOCUMENTS_FAILURE'
)<void, DocumentModel[], Error>();

export const downloadDocumentAsync = createAsyncAction(
    'documents/DOWNLOAD_DOCUMENT_REQUEST',
    'documents/DOWNLOAD_DOCUMENT_SUCCESS',
    'documents/DOWNLOAD_DOCUMENT_FAILURE'
)<number, void, Error>();
