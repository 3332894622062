import { TenantDomains, Tenants } from './tenantDomains';

class TenantProvider {
    getTenant = (): Tenants => {
        const currentTenantDomain = this.getSubdomain();
        /* return 'lm'; */
        for (const domainKey in TenantDomains) {
            const domain = TenantDomains[domainKey];
            if (currentTenantDomain === domain) {
                return domain;
            }
        }

        throw new Error(`Unsupported tenant ${currentTenantDomain}`);
    };

    getSubdomain = (): string => {
        /*  return 'lm'; */
        const full = window.location.host;
        const parts = full.split('.');
        const subWithPort = parts[0];
        const subWithPortParts = subWithPort.split(':');

        return subWithPortParts[0];
    };
}

export default new TenantProvider();
