import { AlertModel, AlertMessage } from './types';
import { addAlert } from './actions';

export const addErrorAlert = (message: string): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'error' }));

    return addAlert(alert);
};

export const addSuccessAlert = (message: string): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'success' }));

    return addAlert(alert);
};

export const addInfoAlerWithCustomComponent = (
    message: string,
    component: React.ComponentType<any>,
    componentProps: any
): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'info', timeout: 30000 }), component, componentProps);

    return addAlert(alert);
};

const addErrorAlerWithCustomComponent = (message: string, component: React.ComponentType<any>, componentProps: any): ReturnType<typeof addAlert> => {
    const alert = new AlertModel(new AlertMessage({ message, type: 'error' }), component, componentProps);

    return addAlert(alert);
};
