import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { ThemaName } from '../types';
import { default as ColorPalettes } from './index';

class PaletteProvider {
    getPallete = (theme: ThemaName): PaletteOptions => {
        for (const themeKey in ColorPalettes) {
            if (themeKey === theme) {
                return ColorPalettes[themeKey];
            }
        }

        throw new Error(`Unsupported theme ${theme}`);
    };
}

export default new PaletteProvider();
