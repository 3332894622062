import { tenantProvider } from '../../features/tenant';

import { default as CEZ_EP_LOGO } from './cez_ep.png';
import { default as LOMY_MORINA_LOGO } from './logo_lomy_morina.png';

class LogoProvider {
    getLogo = (): string => {
        const tenantKey = tenantProvider.getTenant();

        switch (tenantKey) {
            case 'lm':
                return LOMY_MORINA_LOGO;
            case 'ksystem':
                return '';
            case 'cezep':
                return CEZ_EP_LOGO;
            default:
                return '';
        }
    };
}

export default new LogoProvider();
