import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUserTie,
    faIdCardAlt,
    faBell,
    faPowerOff,
    faBars,
    faDownload,
    faFileInvoice,
    faFileAlt,
    faKey,
    faFolderDownload
} from '@fortawesome/pro-light-svg-icons';

library.add(faUserTie, faIdCardAlt, faBell, faPowerOff, faBars, faDownload, faFileInvoice, faFileAlt, faKey, faFolderDownload);

export type IconName =
    | 'user-tie'
    | 'id-card-alt'
    | 'bell'
    | 'power-off'
    | 'bars'
    | 'download'
    | 'file-invoice'
    | 'file-alt'
    | 'key'
    | 'folder-download';

export type IconPrefix = 'fal';
