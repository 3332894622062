import React from 'react';
import { Grid, makeStyles, createStyles, Theme, Typography, Container, Box } from '@material-ui/core';

import UnauthenticatedAppHeader from './unauthenticatedAppHeader';
import UnauthenticatedAppFooter from './unauthenticatedAppFooter';
import { Icon } from '../../components';

const useHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '136px'
        },
        image: {
            height: '64px'
        }
    })
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '100vh',
            [theme.breakpoints.up('md')]: {
                backgroundColor: '#fafafa'
            }
        },
        gridRoot: {
            paddingTop: '50px'
        },
        columnLeft: { backgroundColor: theme.palette.background.paper },
        columnRight: {
            backgroundColor: theme.palette.primary.main,
            textAlign: 'center'
        },
        columnRightUserIcon: {
            fontSize: '18em',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '64px',
            marginBottom: '64px',
            color: 'white',
            opacity: 0.08
        },
        columnRightEmployeeText: {
            marginTop: '100px',
            color: 'white'
        }
    })
);

type UnauthenticatedAppTwoColumnLayoutProps = {
    /* classes: ClassNameMap<UnauthenticatedAppOneColumnLayoutKey>; */
};

const UnauthenticatedAppTwoColumnLayout: React.FunctionComponent<UnauthenticatedAppTwoColumnLayoutProps> = props => {
    const headerClasses = useHeaderStyles();
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <UnauthenticatedAppHeader classes={headerClasses} />
                <Grid container classes={{ root: classes.gridRoot }}>
                    <Grid item xs={6} classes={{ root: classes.columnLeft }}>
                        {props.children}
                    </Grid>
                    <Grid item xs={6} classes={{ root: classes.columnRight }}>
                        <Typography align="center" variant="h4" classes={{ root: classes.columnRightEmployeeText }}>
                            ZAMĚSTNANEC.online
                        </Typography>
                        <Icon iconName="user-tie" IconProps={{ className: classes.columnRightUserIcon }} fixedWidth />
                    </Grid>
                </Grid>
                <UnauthenticatedAppFooter />
            </Container>
        </Box>
    );
};

export default UnauthenticatedAppTwoColumnLayout;
