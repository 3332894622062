import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse } from '../responses';
import { DocumentGroupModel, DocumentGroupType, DocumentType } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class DocumentGroupClient extends BaseAuthenticatedClient {
    constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
        super(authenticatedRequestHandler, errorHandler, axiosInstance, 'documentGroup');
    }

    public async getAsync(type: DocumentGroupType): Promise<BaseDataResponse<DocumentGroupModel[]>> {
        return await this.getHttpAsync<BaseDataResponse<DocumentGroupModel[]>>('filter', `type=${type}`);
    }

    public async getPdfAsync(payrollId: number): Promise<Blob | null> {
        return await this.getHttpBinaryAsync(`${payrollId}/pdf`, '');
    }
}
