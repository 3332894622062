import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { UserInfo } from './types';
import { User } from '../authentication';

const getUser = (state: ApplicationState): DeepReadonly<User | null> => state.authentication.user;

export const getUserInfoSelector = createSelector([getUser], (user): UserInfo | null => {
    if (!user) {
        return null;
    }

    return { fullName: user.fullName, personalId: user.personalId, initials: user.initials };
});
