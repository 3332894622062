import React from 'react';
import { Container, Theme, makeStyles, createStyles } from '@material-ui/core';

import { default as UnauthenticatedAppHeader } from './unauthenticatedAppHeader';
import { default as UnauthenticatedAppFooter } from './unauthenticatedAppFooter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '0px 0px 0 0px',
            backgroundColor: 'white',
            minHeight: '100vh'
        },
        loginText: {
            color: theme.palette.primary.main,
            margin: '30px 0px 0px 0px'
        },
        infoText: {
            padding: '20px 0px 0px 0px',
            lineHeight: 1.4,
            fontSize: '1.12rem'
        },
        form: {
            paddingTop: '20px'
        },
        noAccountText: {
            padding: `${theme.spacing(2)}px 0px 0px `,
            lineHeight: 1.4,
            fontSize: '1.12rem'
        },
        text: {
            padding: `${theme.spacing(3)}px 0px 0px `,
            lineHeight: 1.4,
            fontSize: '1.12rem'
        }
    })
);

type UnauthenticatedAppOneColumnLayoutKey = 'root' | 'loginText' | 'infoText' | 'form' | 'noAccountText' | 'text' | 'footerText';

type UnauthenticatedAppOneColumnLayoutProps = {
    /* classes: ClassNameMap<UnauthenticatedAppOneColumnLayoutKey>; */
};

const UnauthenticatedAppOneColumnLayout: React.FunctionComponent<UnauthenticatedAppOneColumnLayoutProps> = props => {
    const classes = useStyles();

    return (
        <Container maxWidth="sm" classes={{ root: classes.root }}>
            <UnauthenticatedAppHeader />
            {props.children}
            <UnauthenticatedAppFooter />
        </Container>
    );
};

//export default withStyles(styles)(UnauthenticatedAppOneColumnLayout);
export default UnauthenticatedAppOneColumnLayout;
