import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import * as actions from './actions';
import { default as Alert } from './alert';
import { AlertModel } from './types';
import { alertAutoCloseConfig } from '../../configuration/alerts';
import { ApplicationState } from '../../store/rootReducer';

const withStylesFunction = withStyles(({ palette, spacing, paddings }: Theme) => ({
    root: {
        marginBottom: spacing(2),
        '& > * + *': {
            marginTop: spacing(1)
        }
    }
}));

type AlertsContainerClassKey = 'root';

type AlertsContainerProps = {
    autoCloseConfig: typeof alertAutoCloseConfig;
    classes: ClassNameMap<AlertsContainerClassKey>;
} & AlertsContainerStateProps &
    AlertsContainerDispatchProps;

type AlertsContainerStateProps = {
    alerts: AlertModel[];
};

type AlertsContainerDispatchProps = {
    removeAlert(id: string): void;
    clearAlerts(): void;
};

class AlertsContainer extends React.Component<AlertsContainerProps> {
    static defaultProps = {
        autoCloseConfig: alertAutoCloseConfig
    };

    componentWillUnmount(): void {
        this.clearAlerts();
    }

    removeAlert = (alertId: string): void => {
        this.props.removeAlert(alertId);
    };

    clearAlerts = (): void => {
        this.props.clearAlerts();
    };

    render(): React.ReactElement {
        const alerts = this.props.alerts.map(alert => (
            <Alert key={alert.id} alert={alert} onClose={this.removeAlert} autoCloseConfig={this.props.autoCloseConfig} />
        ));

        return <div className={this.props.classes.root}>{alerts}</div>;
    }
}

const mapStateToProps = ({ alerts }: ApplicationState): AlertsContainerStateProps => {
    return {
        alerts: alerts.alerts
    };
};

const mapDispatchToProps = (dispatch: Dispatch): AlertsContainerDispatchProps => {
    return {
        removeAlert: bindActionCreators(actions.removeAlert, dispatch),
        clearAlerts: bindActionCreators(actions.clearAlerts, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(AlertsContainer));
