import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';

import { Tabs } from '../../components';

type PayrollTicketsYearsProps = DeepReadonly<{
    years: number[];
    selectedYear: number;
    onSelectedYearChange(year: number): void;
}>;

const PayrollTicketsYears: React.FunctionComponent<PayrollTicketsYearsProps> = props => {
    console.warn('PayrollTicketsYears');

    return (
        <Tabs tabItems={props.years as []} selectedTabItemKey={props.selectedYear} labelTab="Rok" onSelectedItemChange={props.onSelectedYearChange} />
    );
};

export default PayrollTicketsYears;
