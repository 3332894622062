import { ForkEffect, AllEffect, all, put, takeEvery, PutEffect } from 'redux-saga/effects';
import { genericError } from './actions';
import { addErrorAlertWithTenantFilter } from '../alerts';

export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([yield takeEvery(genericError, genericErrorHandler)]);
}

function* genericErrorHandler(action: ReturnType<typeof genericError>): Generator<ReturnType<typeof addErrorAlertWithTenantFilter>, void, unknown> {
    try {
        yield addErrorAlertWithTenantFilter(action.payload, 'genericError');
    } catch (error) {
        console.error(error);
    }
}
