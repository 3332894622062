import React from 'react';
import { makeStyles, Theme, createStyles, Hidden, Drawer, useTheme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

import MainMenuDrawerContent from './mainMenuDrawerContent';

type MainMenuDrawerClassKey = 'drawer' | 'drawerPaper';
type MainMenuDrawerStyleProps = {};
type MainMenuDrawerProps = {
    mobileOpen: boolean;

    handleDrawerToggle: () => void;
};

// TODO: dat do thematu jako property
const drawerWidthMd = 240;
const drawerWidthLg = 340;

const useStyles = makeStyles(
    (theme: Theme): StyleRules<MainMenuDrawerClassKey, MainMenuDrawerStyleProps> =>
        createStyles({
            drawer: {
                [theme.breakpoints.up('md')]: {
                    width: drawerWidthMd,
                    flexShrink: 0
                },
                [theme.breakpoints.up('lg')]: {
                    width: drawerWidthLg
                }
            },
            drawerPaper: {
                width: drawerWidthMd,
                [theme.breakpoints.up('lg')]: {
                    width: drawerWidthLg
                }
            }
        })
);

const MainMenuDrawer: React.FunctionComponent<MainMenuDrawerProps> = props => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <nav className={classes.drawer} aria-label="main mwenu">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={props.mobileOpen}
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    <MainMenuDrawerContent />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    variant="permanent"
                    open
                >
                    <MainMenuDrawerContent />
                </Drawer>
            </Hidden>
        </nav>
    );
};

export default MainMenuDrawer;
