import { MenuItemModel } from '../features/menu/types';

export const MenuItemsConfiguration: MenuItemModel[] = [
    new MenuItemModel('/payrolltickets', 'Výplatní lístky', 'file-invoice'),
    new MenuItemModel('/payrollticketpattern', 'Vzorový výplatní lístek', 'file-alt'),
    new MenuItemModel('/documents', 'Informace pro zaměstnance', 'folder-download'),
    new MenuItemModel('/shiftSchedules', 'Harmonogramy směn', 'folder-download', ["cezep"]),

    // Vzdy posledni
    new MenuItemModel('/signout', 'Odhlásit', 'power-off')
];
