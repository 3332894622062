import { put } from "redux-saga/effects";
import { isNotificationAllowed , NotificationTypes} from "../notifications";
import { addErrorAlert, addInfoAlerWithCustomComponent, addSuccessAlert } from "./actions.internal";

export function* addErrorAlertWithTenantFilter(message: string, messageType: NotificationTypes) {
    const isAllowed = isNotificationAllowed(messageType);
    if (!isAllowed) {
        return;
    }

    yield put(addErrorAlert(message));
}

export function* addInfoAlerWithCustomComponentWithTenantFilter(
    message: string,
    component: React.ComponentType<any>,
    componentProps: any,
    messageType: NotificationTypes
) {
    const isAllowed = isNotificationAllowed(messageType);
    if (!isAllowed) {
        return;
    }

    yield put(addInfoAlerWithCustomComponent(message, component, componentProps));
}

export function* addSuccessAlertWithTenantFilter(
    message: string,   
    messageType: NotificationTypes
) {
    const isAllowed = isNotificationAllowed(messageType);
    if (!isAllowed) {
        return;
    }

    yield put(addSuccessAlert(message));
}

