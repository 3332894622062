import { Tenants, tenantProvider } from '../../features/tenant';
import { PayrollTicketPatternLanguageType } from './types';

import { default as cezepPayrollTicketPatternImage } from './cezep_payrollTicket_pattern_50.jpg';
import { default as cezepPayrollTicketPatternEnImage } from './cezep_payrollTicket_pattern_EN_50.jpg';

import { default as lmPayrollTicketPatternImage } from './lm_payrollTicket_pattern_50.jpg';
import { default as lmPayrollTicketPatternEnImage } from './lm_payrollTicket_pattern_EN_50.jpg';

const configuration = {
    cezep: {
        cz: cezepPayrollTicketPatternImage,
        en: cezepPayrollTicketPatternEnImage
    },
    lm: {
        cz: lmPayrollTicketPatternImage,
        en: lmPayrollTicketPatternEnImage
    }
} as Record<Tenants, Record<PayrollTicketPatternLanguageType, string>>;

class PayrollTicketPatternProvider {
    getPayrollPattern = (language: PayrollTicketPatternLanguageType): string => {
        const tenantKey = tenantProvider.getTenant();

        switch (tenantKey) {
            case 'lm':
                return configuration.lm[language];
            case 'ksystem':
                return '';
            case 'cezep':
                return configuration.cezep[language];
            default:
                return '';
        }
    };
}

export default new PayrollTicketPatternProvider();
