import { tenantProvider, Tenants } from './';

const RegistrationEmails = {
    "cezep": 'Lenka.vyhnankova@cez.cz'
} as Record<Tenants, string>;

const DefaultRegistrationEmail = 'dev@zamestnanec.online';

class TenantConfigurationService {
    getRegistrationEmail = (): string => {
        const tenantKey = tenantProvider.getTenant();

        for (const emailKey in RegistrationEmails) {
            if (emailKey === tenantKey) {
                return RegistrationEmails[emailKey];
            }
        }

        return DefaultRegistrationEmail;
    };
}

export default new TenantConfigurationService();
