import React from 'react';
import { Typography, Grid, Box, makeStyles, Theme, StyleRules, createStyles } from '@material-ui/core';

const useStyles = makeStyles(
    (theme: Theme): StyleRules<NewSectionNotificationClassKey> =>
        createStyles({
            root: {
                '& a': {
                    color: 'inherit'
                }
            }
        })
);

type NewSectionNotificationClassKey = 'root';

type NewSectionNotificationProps = {
    sectionName: string;
    sectionDescription: string;
    sectionPath: string;
};

const NewSectionNotification = (props: NewSectionNotificationProps): React.ReactElement => {
    const classes = useStyles();

    return (
        <Grid container classes={{ root: classes.root }}>
            {'Novinka: Připravili jsme pro vás novou sekci '}
            <Box ml={0.5}>
                <Typography variant="subtitle2" color="primary">
                    <a href={props.sectionPath}>{props.sectionName}</a>
                </Typography>
            </Box>
            {'. '}
            {props.sectionDescription}.
            <Box ml={0.5}>
                <a href={props.sectionPath}> Přejít do sekce</a>
            </Box>
        </Grid>
    );
};

export default NewSectionNotification;
