import React from 'react';
import { withStyles, Theme } from '@material-ui/core';
import { ClassNameMap, StyleRules } from '@material-ui/core/styles/withStyles';

import { Logo } from '../../components';
import { logoProvider } from '../../customization/logos';

const styles = (theme: Theme): StyleRules<UnauthenticatedAppHeaderKey> => ({
    root: {
        paddingTop: '6px'
    },
    image: {
        height: 32,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

type UnauthenticatedAppHeaderKey = 'root' | 'image';

type UnauthenticatedAppHeaderProps = {
    classes: ClassNameMap<UnauthenticatedAppHeaderKey>;
};

const UnauthenticatedAppHeader: React.FunctionComponent<UnauthenticatedAppHeaderProps> = props => {
    const logo= logoProvider.getLogo();

    return (
        <header className={props.classes.root}>
            <Logo imageSrc={logo} classes={{ root: props.classes.image }} alt="logo" />
        </header>
    );
};

export default withStyles(styles)(UnauthenticatedAppHeader);
