import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse } from '../responses';
import { DocumentModel, DocumentType } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class DocumentClient extends BaseAuthenticatedClient {
    constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
        super(authenticatedRequestHandler, errorHandler, axiosInstance, 'document');
    }

    public async getAsync(type: DocumentType): Promise<BaseDataResponse<DocumentModel[]>> {
        return await this.getHttpAsync<BaseDataResponse<DocumentModel[]>>('filter', `type=${type}`);
    }

    public async getByGroupAsync(groupId: number): Promise<BaseDataResponse<DocumentModel[]>> {
        return await this.getHttpAsync<BaseDataResponse<DocumentModel[]>>(`group/${groupId}`, '');
    }

    public async getPdfAsync(payrollId: number): Promise<Blob | null> {
        return await this.getHttpBinaryAsync(`${payrollId}/pdf`, '');
    }
}
