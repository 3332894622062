import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { PayrollModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class PayrollClient extends BaseAuthenticatedClient {
    constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
        super(authenticatedRequestHandler, errorHandler, axiosInstance, 'payroll');
    }

    public async getAsync(year: number): Promise<BaseDataResponse<PayrollModel[]>> {
        const query = `year=${year}`;

        return await this.getHttpAsync<BaseDataResponse<PayrollModel[]>>('', query);
    }

    public async getPeriodsAsync(): Promise<BaseDataResponse<number[]>> {
        return await this.getHttpAsync<BaseDataResponse<number[]>>('periods', '');
    }

    public async getPdfAsync(payrollId: number): Promise<Blob | null> {
        return await this.getHttpBinaryAsync(`${payrollId}/pdf`, '');
    }

    public async getPdfPinCodeAsync(payrollId: number): Promise<BaseResponse> {
        return await this.getHttpAsync(`${payrollId}/pdf/pinCode`, '');
    }
}
