import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ThemeState, ThemeAction } from './types';
import * as actions from './actions';

export const initialState: ThemeState = {
    themeName: 'default'
};

const themeNameReducer = createReducer<typeof initialState.themeName, ThemeAction>(initialState.themeName).handleAction(
    actions.selectThemeName,
    (state, action) => action.payload
);

export default combineReducers<ThemeState>({
    themeName: themeNameReducer
});
