import { all, ForkEffect, AllEffect } from 'redux-saga/effects';

import asyncActionsSaga from './asyncActionsSaga';
import { signInSaga } from '../features/signIn';
import { signOutSaga } from '../features/signOut';
import { payrollTicketsSaga } from '../features/payrollTickets';
import { authenticationSaga } from '../features/authentication';
import { downloadFileSaga } from '../features/downloadFile';
import { themeSaga } from '../features/themes';
import { errorsSaga } from '../features/errors';
import { appSaga } from '../features/app';
import { documentsSaga } from '../features/documents';
import { shiftSchedulesSaga } from '../features/shiftSchedules';

export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([
        errorsSaga(),
        asyncActionsSaga(),
        authenticationSaga(),
        signInSaga(),
        signOutSaga(),
        payrollTicketsSaga(),
        downloadFileSaga(),
        themeSaga(),
        appSaga(),
        documentsSaga(),
        shiftSchedulesSaga()
    ]);
}
