import { createAction, createAsyncAction } from 'typesafe-actions';

import { PayrollTicketsSuccessResponse } from './types';

export const selectYear = createAction('payrollTickets/SELECT_YEAR')<number>();

export const getPeriodsAsync = createAsyncAction(
    'payrollTickets/GET_PERIODS_REQUEST',
    'payrollTickets/GET_PERIODS_SUCCESS',
    'payrollTickets/GET_PERIODS_FAILURE'
)<void, number[], Error>();

export const getPayrollsAsync = createAsyncAction(
    'payrollTickets/GET_PAYROLLS_REQUEST',
    'payrollTickets/GET_PAYROLLS_SUCCESS',
    'payrollTickets/GET_PAYROLLS_FAILURE'
)<number, PayrollTicketsSuccessResponse, Error>();

export const downloadPayrollAsync = createAsyncAction(
    'payrollTickets/DOWNLOAD_PAYROLL_REQUEST',
    'payrollTickets/DOWNLOAD_PAYROLL_SUCCESS',
    'payrollTickets/DOWNLOAD_PAYROLL_FAILURE'
)<number, void, Error>();

export const getPayrollPinCodeAsync = createAsyncAction(
    'payrollTickets/GET_PAYROLL_PIN_CODE_REQUEST',
    'payrollTickets/GET_PAYROLL_PIN_CODE_SUCCESS',
    'payrollTickets/GET_PAYROLL_PIN_CODE_FAILURE'
)<number, void, Error>();