import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, makeStyles, createStyles, Theme, Container } from '@material-ui/core';

import * as actions from './actions';
import { SignInPageEnum } from './types';
import SignInPersonalIdView from './signInPersonalIdView';
import SignInPinCodeView from './signInPinCodeView';
import { ApplicationState } from '../../store/rootReducer';
import {tenantConfiguratinService} from '../tenant';

type SingInContainerProps = {} & SingInContainerStateProps & SingInContainerDispatchProps;

type SingInContainerStateProps = {
    page: SignInPageEnum;
    errorMessage: string;
    requestInProgress: boolean;
};

type SingInContainerDispatchProps = {
    signInRequestOptRequest: typeof actions.signInRequestOptAsync.request;
    signInValidateOtpRequest: typeof actions.signInValidateOptAsync.request;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: `${theme.spacing(4)}px`,
            minHeight: '100%'
        },
        loginText: {
            color: theme.palette.primary.main,
            /* padding: `${theme.spacing(4)}px 0px 0px 0px` */
        }
    })
);

const getSignInView = (props: SingInContainerProps): React.ReactElement | null => {
    switch (props.page) {
        case SignInPageEnum.PersonalId:
            return (
                <SignInPersonalIdView
                    requestOpt={props.signInRequestOptRequest}
                    errorMessage={props.errorMessage}
                    requestInProgress={props.requestInProgress}
                    supportEmail={tenantConfiguratinService.getRegistrationEmail()}
                />
            );
        case SignInPageEnum.PinCode:
            return (
                <SignInPinCodeView
                    validateOpt={props.signInValidateOtpRequest}
                    errorMessage={props.errorMessage}
                    requestInProgress={props.requestInProgress}
                />
            );
        default:
            return null;
    }
};

const SingInContainer: React.FunctionComponent<SingInContainerProps> = props => {
    const componet = getSignInView(props);
    const classes = useStyles(props);

    return (
        <Container maxWidth="sm" classes={{ root: classes.root }}>
            <Typography align="left" variant="h4" classes={{ root: classes.loginText }}>
                PŘIHLÁŠENÍ
            </Typography>
            {componet}
        </Container>
    );
};

const mapStateToProps = (state: ApplicationState): SingInContainerStateProps => ({
    page: state.signIn.page,
    errorMessage: state.signIn.errorMessage,
    requestInProgress: state.signIn.requestInProgress    
});

const mapDispatchToProps = (dispatch: Dispatch): SingInContainerDispatchProps =>
    bindActionCreators(
        {
            signInRequestOptRequest: actions.signInRequestOptAsync.request,
            signInValidateOtpRequest: actions.signInValidateOptAsync.request
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SingInContainer);
