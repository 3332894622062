// https://github.com/szul/metron/blob/master/src/metron.extenders.ts

Array.prototype.isEmpty = function(): boolean {
    if (this.length === 0) {
      return true;
    }
    
    return false;
  }; 
  
  export default Array;
  