import { all, takeEvery, ForkEffect, AllEffect } from 'redux-saga/effects';

import { downloadFile } from './actions';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([yield takeEvery(downloadFile, downloadFileHandler)]);
}

function downloadFileHandler(action: ReturnType<typeof downloadFile>): void {
    // TODO: : Generator {
    try {
        const downloadFileResponse = action.payload;
        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(downloadFileResponse.data, downloadFileResponse.filename);
        } else {
            const url = window.URL.createObjectURL(downloadFileResponse.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadFileResponse.filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    } catch (error) {
        //TODO: Logovat obecnou chybu
    }
}
