import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SingInContainer } from '../signIn';

type UnauthenticatedAppRouterProps = {};

const UnauthenticatedAppRouter: React.FunctionComponent<UnauthenticatedAppRouterProps> = (): React.ReactElement => {
    console.warn('AuthenticatedAppRouter');

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/signin" />
            </Route>
            <Route path="/signin">
                <SingInContainer />
            </Route>
            <Route
                path="*"
                render={({ location }): React.ReactElement => {
                    if (location.pathname !== '/signout') {
                        return <Redirect to={{ pathname: '/signin', state: { referer: location } }} />;
                    }

                    return <Redirect to="/signin" />;
                }}
            />
        </Switch>
    );
};

export default UnauthenticatedAppRouter;
