import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import { ShiftSchedulesAction, ShiftSchedulesState } from './types';
import * as actions from './actions';

export const initialState: ShiftSchedulesState = {
    documentList: {
        byId: {},
        allIds: [],
        byGroups: {}
    },
    groups: [],
    selectedGroupId: 0
};

const documentListReducer = createReducer<Readonly<typeof initialState.documentList>, ShiftSchedulesAction>(initialState.documentList).handleAction(
    actions.getDocumentsAsync.success,
    (state, action) => {
        const newState = produce(initialState.documentList, draft => {
            action.payload.documents.forEach(document => {
                draft.byId[document.id] = document;
                draft.allIds.push(document.id);
                draft.byGroups[action.payload.groupId] = action.payload.documents;
            });
        });

        return newState;
    }
);

const groupsReducer = createReducer<Readonly<typeof initialState.groups>, ShiftSchedulesAction>(initialState.groups)
    .handleAction(actions.getGroupsAsync.success, (state, action) => action.payload)
    .handleAction(actions.getGroupsAsync.failure, (state, action) => initialState.groups);

const selectedGroupIdReducer = createReducer<Readonly<typeof initialState.selectedGroupId>, ShiftSchedulesAction>(
    initialState.selectedGroupId
).handleAction(actions.selectGroup, (state, action) => action.payload);

export default combineReducers<ShiftSchedulesState>({
    documentList: documentListReducer,
    groups: groupsReducer,
    selectedGroupId: selectedGroupIdReducer
});
