import React, { useState, ReactNode } from 'react';
import { Theme, InputAdornment, Input as MuiInput, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import { InputProps as MuiInputProps } from '@material-ui/core/Input';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { IconName, Icon } from '../../icons';

const withStylesFunction = withStyles(({ palette, spacing, paddings }: Theme) => ({
    labelFormControl: {
        transform: 'translate(44px, 24px) scale(1)'
    },
    iconRoot: {
        width: '1.2em',
        color: palette.text.secondary
    },
    inputInput: {
        fontWeight: 'bold'
    },
    inputRoot: {
        paddingBottom: `calc(${spacing(2)}px - ${paddings.MuiInputBaseInput.Bottom}px)`
    },
    inputStartAdornment: {
        marginRight: spacing(2)
    }
}));

type InputClassKey = 'labelFormControl' | 'iconRoot' | 'inputInput' | 'inputRoot' | 'inputStartAdornment';

type InputProps = {
    classes: ClassNameMap<InputClassKey>;
    iconName: IconName;
    labelText: string;
    helperText: ReactNode;
    error: boolean;
    InputProps?: MuiInputProps;
    InputLabelProps?: MuiInputLabelProps;
};

const Input: React.FunctionComponent<InputProps> = props => {
    const [shrink, setShrink] = useState(false);

    const shrinkLabel = (): void => {
        setShrink(true);
    };

    const unShrinkLabel = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (event.target.value.length === 0) {
            setShrink(false);
        }
    };

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel
                    shrink={shrink}
                    classes={{
                        formControl: !shrink ? props.classes.labelFormControl : undefined
                    }}
                    {...props.InputLabelProps}
                >
                    {props.labelText}
                </InputLabel>
                <MuiInput
                    onFocus={shrinkLabel}
                    onBlur={unShrinkLabel}
                    classes={{
                        root: props.classes.inputRoot,
                        input: props.classes.inputInput
                    }}
                    startAdornment={
                        <InputAdornment position="start" classes={{ root: props.classes.inputStartAdornment }}>
                            <Icon iconName={props.iconName} IconProps={{ className: props.classes.iconRoot }} fixedWidth />
                        </InputAdornment>
                    }
                    {...{ ...props.InputProps, error: props.error }}
                />
                <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
            </FormControl>

            {/* <TextField
          type="text"
          label="Password"
          onFocus={shrinkLabel}
          onBlur={unShrinkLabel}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  className="fal fa-id-card-alt"
                  classes={{ root: props.classes.iconRoot }}
                />
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            shrink: shrink,
            classes: {
              formControl: !shrink ? props.classes.labelFormControl : undefined
            }
          }}
        /> */}
        </React.Fragment>
    );
};

export default withStylesFunction(Input);
