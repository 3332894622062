import { takeEvery, all, put, ForkEffect, AllEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'react-redux/node_modules/redux';
import { PayloadAction , ActionType} from 'typesafe-actions';

import { hideFullPageLoadingIndicator, showFullPageLoadingIndicator } from '../components/fullPageLoadingIndicator';
import { genericError } from '../features/errors';

function* handleAsyncActionRequest(): Generator<PutEffect<ActionType<typeof showFullPageLoadingIndicator>>> {
    yield put(showFullPageLoadingIndicator());
}

function* handleAsyncActionFailure(action: PayloadAction<string, Error>): Generator<
    PutEffect<ActionType<typeof hideFullPageLoadingIndicator>> | PutEffect<ActionType<typeof genericError>>
> {
    yield put(hideFullPageLoadingIndicator());
    yield put(genericError(action.payload.message))
}

function* handleAsyncActionSuccess(): Generator<PutEffect<ActionType<typeof hideFullPageLoadingIndicator>>> {
    yield put(hideFullPageLoadingIndicator());
}

function* watchSuccess(): Generator<ForkEffect<never>> {
    yield takeEvery((action: Action): ForkEffect<never> => {
        return action.type && action.type.toUpperCase().endsWith('SUCCESS');
    }, handleAsyncActionSuccess);
}

function* watchFailure(): Generator<ForkEffect<never>> {
    yield takeEvery((action: Action) => {
        return action.type && action.type.toUpperCase().endsWith('FAILURE');
    }, handleAsyncActionFailure);
}

function* watchRequest(): Generator<ForkEffect<never>> {
    yield takeEvery((action: Action): ForkEffect<never> => {
        return action.type && action.type.toUpperCase().endsWith('REQUEST');
    }, handleAsyncActionRequest);
}

// use them in parallel
export default function* rootSaga(): Generator<AllEffect<Generator<ForkEffect<never>>>> {
    yield all([watchFailure(), watchRequest(), watchSuccess()]);
}
