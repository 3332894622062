import { BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';

const NETWORK_ERROR = 'network error';
const NO_INTERNET_MESSAGE = 'Server není dostupný. Zkontrolujte prosím připojení k internetu a akci opakujte.';

export default class ErrorHandler implements IErrorHandler {
    public async handleRequestAsync<TResponse extends BaseResponse>(request: () => Promise<TResponse>): Promise<TResponse> {
        try {
            const responses = await request();
            console.warn(responses);

            return responses;
        } catch (error) {
            const response = new BaseResponse() as TResponse;
            response.resultCode = -500;
            response.resultReason = 'Akce se nepodařila. Opakujte prosím akci později.';

            if (error.message && error.message.toLowerCase().includes(NETWORK_ERROR)) {
                response.resultReason = NO_INTERNET_MESSAGE;
            }

            return response;
        }
    }
}
