import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import { DocumentsAction, DocumentsState } from './types';
import * as actions from './actions';

export const initialState: DocumentsState = {
    documentList: {
        byId: {},
        allIds: []
    }
};

const documentListReducer = createReducer<Readonly<typeof initialState.documentList>, DocumentsAction>(initialState.documentList).handleAction(
    actions.getDocumentsAsync.success,
    (state, action) => {
        const newState = produce(initialState.documentList, draft => {
            action.payload.forEach(document => {
                draft.byId[document.id] = {
                    ...document,
                    publicFrom: document.publicFromUtc ? new Date(document.publicFromUtc).toLocaleDateString() : 'nenastaveno'
                };
                draft.allIds.push(document.id);
            });
        });

        return newState;
    }
);

export default combineReducers<DocumentsState>({
    documentList: documentListReducer
});
