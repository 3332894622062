import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { AppProvider } from '../app';
import { ApplicationState } from '../../store/rootReducer';
import { default as themeService } from './themeService';
import * as actions from './actions';
import { ThemaName } from './types';

type ThemedAppContainerProps = {} & ThemedAppContainerStateProps & ThemedAppContainerDispatchProps;

type ThemedAppContainerStateProps = {
    themeName: ThemaName;
};

type ThemedAppContainerDispatchProps = {
    detectThemeName: typeof actions.detectThemeName;
};

const ThemedAppContainer: React.FunctionComponent<ThemedAppContainerProps> = props => {
    const detectThemeName = props.detectThemeName;

    useEffect(() => {
        console.warn('ThemedAppContainer.useEffect');

        detectThemeName();
    }, [detectThemeName]);

    console.warn('ThemedAppContainer');

    const theme = themeService.createTheme(props.themeName);
    console.warn(theme);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppProvider />
        </ThemeProvider>
    );
};

const mapStateToProps = (state: ApplicationState): ThemedAppContainerStateProps => {
    return {
        themeName: state.theme.themeName
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ThemedAppContainerDispatchProps =>
    bindActionCreators(
        {
            detectThemeName: actions.detectThemeName
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ThemedAppContainer);
