import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import { PayrollTicketsState, PayrollTicketsAction } from './types';
import * as actions from './actions';
import { currentYear } from '../../configuration/environment';

export const initialState: PayrollTicketsState = {
    years: [currentYear],
    selectedYear: currentYear,
    payrollTicketList: {
        byYears: { [currentYear]: [] }
    }
};

const yearsReducer = createReducer<Readonly<typeof initialState.years>, PayrollTicketsAction>(initialState.years)
    .handleAction(actions.getPeriodsAsync.success, (state, action) => action.payload)
    .handleAction(actions.getPeriodsAsync.failure, (state, action) => initialState.years);

const selectedYearReducer = createReducer<Readonly<typeof initialState.selectedYear>, PayrollTicketsAction>(initialState.selectedYear).handleAction(
    actions.selectYear,
    (state, action) => action.payload
);

const payrollTicketListReducer = createReducer<Readonly<typeof initialState.payrollTicketList>, PayrollTicketsAction>(
    initialState.payrollTicketList
).handleAction(actions.getPayrollsAsync.success, (state, action) => {
    const newState = produce(state, draft => {
        draft.byYears[action.payload.year] = action.payload.payrolls;
    });

    return newState;
});

export default combineReducers<PayrollTicketsState>({
    years: yearsReducer,
    selectedYear: selectedYearReducer,
    payrollTicketList: payrollTicketListReducer
});
