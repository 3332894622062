import React, { useCallback, useState } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';

import { Tabs } from '../../components';
import payrollTicketPatternProvider from './payrollTicketPatternProvider';
import { PayrollTicketPatternLanguageTabItem, PayrollTicketPatternLanguageType } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        image: {
            maxWidth: '100%'
        }
    })
);

type PayrollTicketPatternContainerProps = {};

const languages: PayrollTicketPatternLanguageTabItem[] = [
    { name: 'CZ', type: 'cz' },
    { name: 'EN', type: 'en' }
];

const PayrollTicketPatternContainer: React.FunctionComponent<PayrollTicketPatternContainerProps> = props => {
    const classes = useStyles();
    const [selectedLanguage, onSelectedLanguageChange] = useState<PayrollTicketPatternLanguageType>('cz');

    const onSelectedItemChange = useCallback((selectedItem: PayrollTicketPatternLanguageType) => {
        onSelectedLanguageChange(selectedItem);
    }, []);

    const imageSrc = payrollTicketPatternProvider.getPayrollPattern(selectedLanguage);

    const tabItemKeySelector = useCallback((tabItem: PayrollTicketPatternLanguageTabItem) => {
        return tabItem.type;
    }, []);

    const tabItemLabelSelector = useCallback((tabItem: PayrollTicketPatternLanguageTabItem) => {
        return tabItem.name;
    }, []);

    return (
        <React.Fragment>
            <Tabs
                labelTab="Jazyk"
                onSelectedItemChange={onSelectedItemChange}
                selectedTabItemKey={selectedLanguage}
                tabItems={languages}
                tabItemKeySelector={tabItemKeySelector}
                tabItemLabelSelector={tabItemLabelSelector}
            />
            <div>
                <img src={imageSrc} className={classes.image} alt="Vzor výplatního lístku" />
            </div>
        </React.Fragment>
    );
};

export default PayrollTicketPatternContainer;
