import { PayrollModel } from '../../api/interfaces';
import { PayrollTicket } from './types';

export const payrollModelsToPayrollTicketMapper = (payrolls: PayrollModel[]): PayrollTicket[] => {
    return payrolls.map(payroll => {
        return {
            id: payroll.id,
            name: payroll.name,
            year: payroll.year,
            month: payroll.month,
            period: `${payroll.month.toLocaleString('cs-CZ', {minimumIntegerDigits: 2, useGrouping:false})}/${payroll.year}`
        } as PayrollTicket;
    });
};
