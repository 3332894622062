import { AxiosRequestConfig } from 'axios';

import { tenantProvider } from '../../features/tenant';

export default function tenantInterceptor(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
    const tenant = tenantProvider.getTenant();

    requestConfig.headers = { ...requestConfig.headers, 'X-Tenant': tenant };

    return requestConfig;
}
