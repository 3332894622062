import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { signInPinCodeValidationSchema } from './validations';
import { useSignInStyles } from './styles';

type SingInPinCodeViewProps = {
    errorMessage?: string;
    requestInProgress: boolean;

    validateOpt: (parsonalId: string) => void;
};

type SingInPinCode = Yup.InferType<typeof signInPinCodeValidationSchema>;

const nameof = nameofFactory<SingInPinCode>();

const SingInPinCodeView: React.FunctionComponent<SingInPinCodeViewProps> = props => {
    const classes = useSignInStyles(props);
    const { errorMessage, requestInProgress } = props;

    return (
        <Formik<SingInPinCode>
            initialValues={{ pinCode: '' }}
            onSubmit={(values: SingInPinCode, { setSubmitting }): void => {
                setSubmitting(true);

                props.validateOpt(values.pinCode);
            }}
            validationSchema={signInPinCodeValidationSchema}
        >
            {(formProps): React.ReactElement => {
                const { values, touched, errors, /* isSubmitting, */ handleChange, handleBlur, handleSubmit } = formProps;
                return (
                    <form onSubmit={handleSubmit}>
                        <Typography align="left" variant="h6" classes={{ root: classes.infoText }}>
                            Zadejte PIN kód, který Vám byl zaslán na Vaše telefonní číslo, které máte nahlášené u zaměstnavatele. Platnost PIN kódu
                            je 5 minut.
                        </Typography>
                        <Grid container className={classes.grid} spacing={3}>
                            <Grid item xs={12}>
                                <Input
                                    iconName="id-card-alt"
                                    labelText="PIN kód"
                                    helperText={touched.pinCode ? errors.pinCode || errorMessage : ''}
                                    error={Boolean(touched.pinCode) && (Boolean(errors.pinCode) || Boolean(errorMessage))}
                                    InputProps={{
                                        name: nameof('pinCode'),
                                        onChange: handleChange,
                                        onBlur: handleBlur,
                                        value: values.pinCode,
                                        autoFocus: true
                                    }}
                                />
                            </Grid>
                            <Grid item container justify="flex-end" xs={12}>
                                <Button variant="contained" title="Zaslat PIN kód" color="primary" type="submit" disabled={requestInProgress}>
                                    Přihlásit se
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
};

export default SingInPinCodeView;
