import React from 'react';
import { Hidden } from '@material-ui/core';

import { default as UnauthenticatedAppOneColumnLayout } from './unauthenticatedAppOneColumnLayout';
import { default as UnauthenticatedAppTwoColumnLayout } from './unauthenticatedAppTwoColumnLayout';
import UnauthenticatedAppRouter from './unauthenticatedAppRouter';

type UnauthenticatedAppProps = {};

const UnauthenticatedApp: React.FunctionComponent<UnauthenticatedAppProps> = props => {
    const content = <UnauthenticatedAppRouter />;

    return (
        <React.Fragment>
            <Hidden mdUp>
                <UnauthenticatedAppOneColumnLayout children={content} />
            </Hidden>
            <Hidden smDown>
                <UnauthenticatedAppTwoColumnLayout children={content} />
            </Hidden>
        </React.Fragment>
    );
};

export default UnauthenticatedApp;
