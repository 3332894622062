import React, { useCallback } from 'react';
import { DeepReadonly } from 'utility-types';

import { DocumentGroupModel } from '../../api/interfaces';

import { Tabs } from '../../components';

type ShiftSchedulesGroupsProps = DeepReadonly<{
    groups: DocumentGroupModel[];
    selectedGroupId: number;
    onSelectedYearChange(groupId: number): void;
}>;

const ShiftSchedulesGroups: React.FunctionComponent<ShiftSchedulesGroupsProps> = props => {
    console.warn('ShiftSchedulesGroups');

    const tabItemKeySelector = useCallback((tabItem: DocumentGroupModel) => {
        return tabItem.id;
    }, []);

    const tabItemLabelSelector = useCallback((tabItem: DocumentGroupModel) => {
        return tabItem.name;
    }, []);

    return (
        <Tabs
            tabItems={props.groups as DocumentGroupModel[]}
            selectedTabItemKey={props.selectedGroupId}
            labelTab="Rok"
            onSelectedItemChange={props.onSelectedYearChange}
            tabItemKeySelector={tabItemKeySelector}
            tabItemLabelSelector={tabItemLabelSelector}
        />
    );
};

export default ShiftSchedulesGroups;
