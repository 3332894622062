export default class BaseResponse {
    public resultCode: number;
    public resultReason: string;
    public httpStatusCode: number;

    constructor() {
        this.resultCode = 0;
        this.resultReason = '';
        this.httpStatusCode = 0;
    }
}
