import { createSelector } from 'reselect';

import { ApplicationState } from '../../store/rootReducer';
import { DeepReadonly } from 'utility-types';
import { PayrollTicket, ByYearsState } from './types';

const getPayrolTicketsSelectedYear = (state: ApplicationState): number => state.payrollTickets.selectedYear;
const getPayrolTicketsByYears = (state: ApplicationState): ByYearsState<PayrollTicket> => state.payrollTickets.payrollTicketList.byYears;

export const selectSelectedYear = getPayrolTicketsSelectedYear;
export const selectPayrolTicketsByYear = (year: number) => (state: ApplicationState): DeepReadonly<PayrollTicket[]> =>
    getPayrolTicketsByYears(state)[year] ? getPayrolTicketsByYears(state)[year] : [];
export const selectPayrollById = (payrollId: number) => (state: ApplicationState): DeepReadonly<PayrollTicket> | undefined => {
    const selectedYear = getPayrolTicketsSelectedYear(state);
    const payrolls = getPayrolTicketsByYears(state)[selectedYear];

    return payrolls.find(payroll => payroll.id === payrollId);
};

export const selectPayrolTicketsBySelectedYear = createSelector(
    [getPayrolTicketsByYears, getPayrolTicketsSelectedYear],
    (byYears, selectedYear): DeepReadonly<PayrollTicket[]> => {
        return byYears[selectedYear] ? byYears[selectedYear] : [];
    }
);
