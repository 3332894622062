import React, { memo } from 'react';
import { makeStyles, createStyles, Theme, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from '@material-ui/core';
import { StyleRules, ClassNameMap } from '@material-ui/core/styles/withStyles';
import { DeepReadonly } from 'utility-types';

import Document from '../../models/document';
import { Icon } from '../../icons';

const useStyles = makeStyles(
    (theme: Theme): StyleRules<TableRowClassKey> =>
        createStyles({
            root: {},
            listRoot: {
                marginTop: theme.spacing(2),
                borderRadius: '0px',
                boxShadow: 'none'
            },
            table: {},
            /*  tableCellRoot: {
                borderBottomColor: theme.palette.listLine
            }, */
            tableCellBody: {
                color: theme.palette.text.secondary
                /* padding: '0px 16px' */
            } /* 
            tableCellHead: {
                color: theme.palette.text.primary
            },*/,
            tableRowAnyItem: { padding: '16px' },
            actionIconRoot: {
                '&:not(:last-of-type)': {
                    marginRight: '15px'
                }
            },
            actionIcon: {
                fontSize: '1.2rem'
            },
            publicFromColumn: {
                minWidth: '120px'
            }
        })
);

type TableRowClassKey = 'root' | 'listRoot' | 'tableRowAnyItem' | 'tableCellBody' | 'table' | 'actionIconRoot' | 'actionIcon' | 'publicFromColumn';

type PayrollTicketsTableProps = DeepReadonly<{
    rows: Document[];

    downloadDocumentAction: (documentId: number) => void;
}>;

const getTableRows = (
    rows: DeepReadonly<Document[]>,
    selected: number,
    classes: ClassNameMap<TableRowClassKey>,
    handleClick: (event: React.MouseEvent<unknown>, id: number) => void,
    handleActionDownloadDocument: (documentId: number) => void
): React.ReactElement[] | React.ReactElement => {
    if (rows.length === 0) {
        return (
            <TableRow key="emptyRow" className={classes.tableRowAnyItem}>
                <TableCell classes={{ body: classes.tableCellBody }} align="left" variant="head" colSpan={3}>
                    <Typography align="center">Aktuálně nejsou k dispozici žádné dokumenty ke stažení.</Typography>
                </TableCell>
            </TableRow>
        );
    }

    return rows.map(row => (
        <TableRow
            key={row.name}
            /*  hover */
            onClick={(event): void => handleClick(event, row.id)}
        >
            <TableCell classes={{ body: classes.tableCellBody }} align="left" variant="body">
                {row.name}
            </TableCell>
            <TableCell classes={{ body: classes.tableCellBody, root: classes.publicFromColumn }} align="left" variant="body">
                {row.publicFrom}
            </TableCell>
            <TableCell classes={{ body: classes.tableCellBody }} align="left" variant="body">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Icon
                        iconName="download"
                        className={classes.actionIcon}
                        IconProps={{
                            classes: { root: classes.actionIconRoot },
                            color: 'primary',
                            title: 'Stáhnout',
                            style: { cursor: 'pointer' },
                            onClick: (): void => handleActionDownloadDocument(row.id)
                        }}
                    />
                </Box>
            </TableCell>
        </TableRow>
    ));
};

const DocumentsTable: React.FunctionComponent<PayrollTicketsTableProps> = memo(props => {
    console.warn('DocumentsTable');
    const classes = useStyles();
    const defaultSelectedValue = props.rows[0] ? props.rows[0].id : -1;
    const [selected, setSelected] = React.useState<number>(defaultSelectedValue);
    const handleClick = (event: React.MouseEvent<unknown>, id: number): void => {
        setSelected(id);
    };

    const rows = getTableRows(props.rows, selected, classes, handleClick, props.downloadDocumentAction);

    return (
        <Table className={classes.table} aria-label="documents table">
            <TableHead>
                <TableRow>
                    <TableCell>Název</TableCell>
                    <TableCell classes={{ root: classes.publicFromColumn }}>Datum</TableCell>
                    <TableCell>Akce</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
        </Table>
    );
});

export default DocumentsTable;
