import React from 'react';
import { makeStyles, Theme, createStyles, Typography, Box } from '@material-ui/core';
import { connect } from 'react-redux';

import MainMenuContent from './mainMenuContent';
import { UserInfo } from './types';
import { ApplicationState } from '../../store/rootReducer';
import { getUserInfoSelector } from './selectors';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        appBar: {
            boxShadow: 'none',
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth
            }
        },
        toolBar: {
            backgroundColor: 'white'
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        grow: {
            flexGrow: 1
        },
        sectionDesktop: {
            display: 'none',
            /*  minHeight: "inherit", */
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                width: 'fit-content'
            }
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            paddingTop: theme.spacing(1)
        },
        userInfoPersonalId: {
            //fontSize: "0.9rem",
            fontWeight: 500
        },
        userInfoAvatar: {
            width: '5rem',
            height: '5rem'
        },
        menuItemRoot: {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        menuItemDivider: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        menuItemIcon: {
            fontSize: '1.2rem',
            color: theme.palette.text.secondary
        },
        menuItemText: {
            fontSize: '1.2rem'
        },
        menuItemActive: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        menuItemInActive: {
            width: '0px',
            backgroundColor: 'transparent'
        }
    })
);

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'primary.main',
    m: 1,
    border: 1.5,
    style: { width: '5rem', height: '5rem' }
};

type MainMenuDrawerContentContainerProps = {} & MainMenuDrawerContentContainerStateProps;

type MainMenuDrawerContentContainerStateProps = {
    userInfo: UserInfo | null;
};

const MainMenuDrawerContentContainer: React.FunctionComponent<MainMenuDrawerContentContainerProps> = props => {
    const classes = useStyles();

    console.warn('MainMenuDrawerContent');
    if (!props.userInfo) {
        return null;
    }

    return (
        <React.Fragment>
            <section className={classes.userInfo}>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Box borderRadius="50%" {...{ ...defaultProps, m: 2 }} display="flex" justifyContent="center" alignItems="center">
                        <Typography align="center" variant="h5">
                            {props.userInfo.initials}
                        </Typography>
                    </Box>

                    {/* <Avatar classes={{ root: classes.userInfoAvatar }}>MK</Avatar> */}
                </Box>
                <Box mt={2}>
                    <Typography align="center" variant="h5">
                        {props.userInfo.fullName}
                    </Typography>
                </Box>
                <Typography className={classes.userInfoPersonalId} align="center" variant="subtitle1" color="textSecondary">
                    {props.userInfo.personalId}
                </Typography>
            </section>
            <MainMenuContent />
        </React.Fragment>
    );
};

const mapStateToProps = (state: ApplicationState): MainMenuDrawerContentContainerStateProps => {
    return {
        userInfo: getUserInfoSelector(state)
    };
};

export default connect(mapStateToProps)(MainMenuDrawerContentContainer);
