import React from 'react';

import { tenantProvider } from '../../../features/tenant';

const SignInPersonalViewInfoPanelLazyLoader = (): React.ComponentType<any> => {
    const tenantKey = tenantProvider.getTenant();

    switch (tenantKey) {
        case 'lm':
            return React.lazy(() => import('./signInPersonalViewInfoPanel.lm'));
        case 'ksystem':
            return React.lazy(() => import('./signInPersonalViewInfoPanel.ksystem'));
        case 'cezep':
            return React.lazy(() => import('./signInPersonalViewInfoPanel.cezep'));

        default:
            return React.lazy(() => import('./signInPersonalViewInfoPanel.default'));
    }
};

const SignInPersonalViewInfoPanelLazy = SignInPersonalViewInfoPanelLazyLoader();

export default SignInPersonalViewInfoPanelLazy;
