import React from 'react';
import { Typography, Paper, createStyles, Theme, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import DocumentsTable from './documentsTable';
import * as actions from './actions';

import { selectDocuments } from './selectors';
import Document from '../../models/document';

const withStylesFunction = withStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flex: "1" */
        },
        listRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none'
        },
        table: {},
        tableCellRoot: {
            borderBottomColor: theme.palette.listLine
        },
        tableCellBody: {
            color: theme.palette.text.secondary,
            padding: '0px 16px'
        },
        tableCellHead: {
            color: theme.palette.text.primary
        },
        tabsRoot: {
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        },
        tabRoot: {
            minWidth: '90px'
        },
        tabIndicator: {
            height: '3px'
        },
        tabTextColorPrimary: {
            '&.Mui-disabled': {
                color: theme.palette.primary.main
            },
            '&.Mui-selected': {
                color: theme.palette.text.primary
            }
        },
        infoTextRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none',
            padding: theme.spacing(2)
        },
        /* warningTextRoot: {
        marginTop: theme.spacing(2),           
    }, */
        infoTextActionIconRoot: {
            margin: '0px 5px'
        },
        infoTextActionIcon: {
            fontSize: '1.2rem'
        }
    })
);

type DocumentsContainerStateProps = DeepReadonly<{
    documents: Document[];
}>;

type DocumentsContainerDispatchProps = {
    getDocumentsAction: typeof actions.getDocumentsAsync.request;
    downloadDocumentAction: typeof actions.downloadDocumentAsync.request;
};

type DocumentsContainerProps = { classes: any } & DocumentsContainerStateProps & DocumentsContainerDispatchProps;

type DocumentsContainerClassKey = 'root';

class DocumentsContainer extends React.Component<DocumentsContainerProps> {
    componentDidMount(): void {
        this.props.getDocumentsAction();
    }

    render(): React.ReactElement {
        return (
            <div className={this.props.classes.root}>
                <Typography variant="h4" color="primary">
                    Informace pro zaměstnance
                </Typography>

                <Paper className={this.props.classes.listRoot} aria-label="list of documents for employees">
                    <DocumentsTable rows={this.props.documents} downloadDocumentAction={this.props.downloadDocumentAction} />
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): DocumentsContainerStateProps => {
    return {
        documents: selectDocuments(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DocumentsContainerDispatchProps =>
    bindActionCreators(
        {
            getDocumentsAction: actions.getDocumentsAsync.request,
            downloadDocumentAction: actions.downloadDocumentAsync.request,
        },
        dispatch
    );

export default withStylesFunction(connect(mapStateToProps, mapDispatchToProps)(DocumentsContainer));
