import { IconName } from '../../icons';
import { Tenants } from '../tenant';

export class MenuItemModel {
    to: string;
    lable: string;
    icon: IconName;
    onlyForTenants?: Tenants[];

    constructor(to: string, label: string, icon: IconName, onlyForTenants?: Tenants[]) {
        this.to = to;
        this.lable = label;
        this.icon = icon;
        this.onlyForTenants = onlyForTenants;
    }
}

export type UserInfo = {
    fullName: string | undefined;
    initials: string | undefined;
    personalId: string | undefined;
};
