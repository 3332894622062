import { all, put, takeEvery, ForkEffect, AllEffect, PutEffect, call, CallEffect } from 'redux-saga/effects';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { tryLoadAuthenticatedUserFormCredentials, setAuthenticatedUserFormCredentials } from './actions';
import { User } from './types';
import { signOutAsync } from '../signOut';
import { signInValidateOptAsync } from '../signIn';
import { ActionType } from 'typesafe-actions';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([
        yield takeEvery(tryLoadAuthenticatedUserFormCredentials, tryLoadAuthenticatedUserFormCredentialHandler),
        yield takeEvery(signOutAsync.success, sigOutSuccess),
        yield takeEvery(signInValidateOptAsync.success, signInSuccess)
    ]);
}

function* tryLoadAuthenticatedUserFormCredentialHandler(
    action: ReturnType<typeof tryLoadAuthenticatedUserFormCredentials>
): Generator<LoadAuthenticatedUserFormCredentialGenerator, void, unknown> {
    // TODO: AsyncGenerator {
    try {
        yield loadAuthenticatedUserFormCredential();
    } catch (error) {
        //TODO: Obecnou akci na chybu, reducer a nejak to propagovat do stavu
    }
}

type TokenDto = {
    unique_name: string;
    nameid: string;
    tenanthost: string;
    initials: string;
    exp: number;
    expiration: string;
};

type LoadAuthenticatedUserFormCredentialGeneratorType = CallEffect | PutEffect<ActionType<typeof setAuthenticatedUserFormCredentials>>;
type LoadAuthenticatedUserFormCredentialGenerator = Generator<LoadAuthenticatedUserFormCredentialGeneratorType, void, unknown>;

function* loadAuthenticatedUserFormCredential(): LoadAuthenticatedUserFormCredentialGenerator {
    console.warn('tryLoadAuthenticatedUserFormCredentialHandler');

    const token = cookies.get('token');
    if (!token) {
        return;
    }

    //TODO: vymyslet
    const decodedToken = jwtDecode<TokenDto>(token);

    console.warn(decodedToken);

    if (Date.now() >= decodedToken.exp * 1000) {
        // Je expirovany, neni co obnovit
        return;
    }

    const dateOfUserExpiry = decodedToken.expiration ? new Date(decodedToken.expiration) : null;

    // Neni expirovany, muzeme hydratovat uzivatele
    yield put(
        setAuthenticatedUserFormCredentials({
            fullName: decodedToken.unique_name,
            personalId: decodedToken.nameid,
            tenantHost: decodedToken.tenanthost,
            initials: decodedToken.initials,
            dateOfUserExpiry: dateOfUserExpiry
        } as User)
    );
}

function* sigOutSuccess(action: ReturnType<typeof signOutAsync.success>): Generator<void, void, unknown> {
    // TODO: AsyncGenerator {
    try {
        yield console.warn('sigOutSuccess');

        const token = cookies.get('token');
        if (!token) {
            return;
        }

        cookies.remove('token');
    } catch (error) {
        //TODO: Obecnou akci na chybu, reducer a nejak to propagovat do stavu
    }
}

function* signInSuccess(
    action: ReturnType<typeof signInValidateOptAsync.success>
): Generator<LoadAuthenticatedUserFormCredentialGenerator, void, unknown> {
    // TODO: AsyncGenerator {
    try {
        yield loadAuthenticatedUserFormCredential();
    } catch (error) {
        //TODO: Obecnou akci na chybu, reducer a nejak to propagovat do stavu
    }
}
