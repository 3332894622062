import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProps as MuiIconProps, default as MuiIcon } from '@material-ui/core/Icon';

import { IconPrefix, IconName } from '../../icons';

type IconProps = {
    fixedWidth?: boolean;
    iconPrefix?: IconPrefix;
    IconProps?: MuiIconProps;
    iconName: IconName;
    className?: string;
};

export default (props: IconProps): React.ReactElement => {
    const { iconName, iconPrefix, IconProps, className, ...otherProps } = props;

    let usedIconPrefix = iconPrefix;
    if (!usedIconPrefix) {
        usedIconPrefix = 'fal';
    }

    let usedClassName = className;
    if (!usedClassName) {
        usedClassName = 'moje';
    }

    return (
        <MuiIcon {...IconProps}>
            <FontAwesomeIcon icon={[usedIconPrefix, iconName]} className={className} {...otherProps} />
        </MuiIcon>
    );
};
