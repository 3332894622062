import { put, all, takeEvery, select, PutEffect, SelectEffect, ForkEffect, AllEffect } from 'redux-saga/effects';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { Location } from 'history';
import { ActionType } from 'typesafe-actions';

import apiClient from '../../api/apiClientInstance';
import { signInRequestOptAsync, signInValidateOptAsync } from './actions';
import { getPersonalId } from './selectors';
import { BaseResponse } from '../../api/responses';
import { Path, LocationState } from 'history';
import { ApplicationState } from '../../store/rootReducer';
import {clearAlerts} from '../alerts'

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([yield takeEvery(signInRequestOptAsync.request, signInRequestOpt), yield takeEvery(signInValidateOptAsync.request, signInValidateOpt)]);
}

const getLocationSelector = (state: ApplicationState): Location<unknown> => state.router.location;

type SignInRequestOptGeneratorType =
    | Promise<BaseResponse>
    | PutEffect<ActionType<typeof signInRequestOptAsync.success>>
    | PutEffect<ActionType<typeof signInRequestOptAsync.failure>>;

function* signInRequestOpt(action: ReturnType<typeof signInRequestOptAsync.request>): Generator<SignInRequestOptGeneratorType, void, BaseResponse> {
    // TODO: AsyncGenerator {
    try {
        console.warn(action.payload);

        const response: BaseResponse = yield apiClient.Authentication.sendOtpAsync(action.payload);
        if (response.resultCode === 0) {
            yield put(signInRequestOptAsync.success(action.payload));
        } else {
            yield put(signInRequestOptAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(signInRequestOptAsync.failure(error));
        }
    }
}

type SignInValidateOptGeneratorType =
    | SelectEffect
    | Promise<BaseResponse>
    | PutEffect<CallHistoryMethodAction<[Path, LocationState?]>>
    | PutEffect<ActionType<typeof signInValidateOptAsync.success>>
    | PutEffect<ActionType<typeof clearAlerts>>
    | PutEffect<ActionType<typeof signInValidateOptAsync.failure>>;

type SignInValidateOptGeneratorNextType = any | BaseResponse;
type SignInValidateOptGeneratorReturnType = PutEffect<CallHistoryMethodAction<[Path, LocationState?]>> | void;

function* signInValidateOpt(
    action: ReturnType<typeof signInValidateOptAsync.request>
): Generator<SignInValidateOptGeneratorType, SignInValidateOptGeneratorReturnType, SignInValidateOptGeneratorNextType> {
    // TODO: AsyncGenerator {
    try {
        console.warn(action.payload);

        const personalId = yield select(getPersonalId);
        const response: BaseResponse = yield apiClient.Authentication.loginAsync(personalId, action.payload);
        if (response.resultCode === 0) {
            yield put(signInValidateOptAsync.success());
            yield put(clearAlerts());

            const location = yield select(getLocationSelector);
            console.warn(location);

            if (location.state && location.state.referer) {
                return yield put(push(location.state.referer.pathname));
            }

            yield put(push('/'));
        } else {
            yield put(signInValidateOptAsync.failure(new Error(response.resultReason)));
        }
    } catch (error) {
        yield put(signInValidateOptAsync.failure(error));
    }
}
